import storage from "services/storage";
import http from "./http-common";

const getActive = () => {
  return http.get(`/jackpot`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getAll = (data) => {
  return http.post(`/jackpot/all`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const getWinners = (id, data) => {
  return http.post(`/jackpot/winners/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const trigger = (data) => {
  return http.post(`/jackpot/trigger`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const reset = (data) => {
  return http.post(`/jackpot/reset`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const reset20 = (data) => {
  return http.post(`/jackpot/reset-20`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const reset500 = (data) => {
  return http.post(`/jackpot/reset-500`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const JackpotServices = {
  getActive,
  getAll,
  getWinners,
  trigger,
  reset,
  reset20,
  reset500,
};

export default JackpotServices;
