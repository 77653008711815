import storage from "services/storage";
import http, { httpForm } from "./http-common";

const getAll = () => {
  return http.get(`/whitelist_states`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const update = (id, data) => {
  return http.put(
    `/whitelist_states/${id}`,
    data,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const PaymentsServices = {
  getAll,
  update,
};

export default PaymentsServices;
