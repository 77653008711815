import storage from "services/storage";
import http from "./http-common";


const getAll = ({page, perPage, search}) => {
  return http.get(`/coupons?page=${page}&search=${search}&perPage=${perPage}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const create = (data) => {
  return http.post(
    `/coupons`,
    data,
    {
      headers: {
        "Content-type":  "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const edit = (id, data) => {
  return http.put(
    `/coupons/${id}`,
    data,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const enable = (id, data) => {
  return http.put(
    `/coupons/${id}/enable`,
    data,
    {
      headers: {
        "Content-type": "multipart/form-data",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const disable = (id, data) => {
  return http.put(
    `/coupons/${id}/disable`,
    data,
    {
      headers: {
        "Content-type": "multipart/form-data",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const expire = (id, data) => {
  return http.put(
    `/coupons/${id}/expire`,
    data,
    {
      headers: {
        "Content-type": "multipart/form-data",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};
const CouponsServices = {
  getAll,
  create,
  edit,
  enable,
  disable,
  expire
}
export default CouponsServices;
