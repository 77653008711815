import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  artists: [],
  requests: [],
};

const artistSlice = createSlice({
  name: "Affiliate Artist",
  initialState,

  reducers: {
    getArtist: (state, action) => {
      state.artists = action.payload;
    },

    deleteArtist: (state, action) => {
      const id = action.payload;
      const index = state.artists.findIndex((item) => item._id === action.payload);
      state.artists.splice(index, 1);
    },
    getRequests: (state, action) => {
      state.requests = action.payload;
    },
  },
});

export const { getArtist, deleteArtist, getRequests } = artistSlice.actions;
export default artistSlice.reducer;
