import storage from "services/storage";
import http, { httpForm } from "./http-common";

const getProfile = () => {
  return http.get(`/auth/splash`, 
  {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};



const ProfileServices = {
  getProfile,
 };

export default ProfileServices;
