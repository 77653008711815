import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";
import PaymentsServices from "store/api/paymentsServices";

const initialState = {
  states: [],
  statesStatus: "idle",
};

export const retriveStates = createAsyncThunk("allStates/retrieve", async ({ history }) => {
  const res = await PaymentsServices.getAll();
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});
export const updtaState = createAsyncThunk("state/update", async ({ id, data, history }) => {
  const res = await PaymentsServices.update(id, data);

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else if (res.data.success === false) {
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    return res.data;
  }
});

const paymenetSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(retriveStates.pending, (state, action) => {
      state.statesStatus = "pending";
    }),
      builder.addCase(retriveStates.fulfilled, (state, action) => {
        state.states = action.payload?.states || [];
        state.statesStatus = "fullfilled";
      }),
      builder.addCase(retriveStates.rejected, (state, action) => {
        state.statesStatus = "rejected";
        state.states = [];
      }),
      builder.addCase(updtaState.pending, (state, action) => {
        state.statesStatus = "pending";
      }),
      builder.addCase(updtaState.fulfilled, (state, action) => {
        const index = state.states.findIndex((item) => item?._id === action.payload?.data?._id);
        state.states[index] = {
          ...state.states[index],
          ...action.payload?.data,
        };
        state.statesStatus = "fullfilled";
      }),
      builder.addCase(updtaState.rejected, (state, action) => {
        state.statesStatus = "rejected";
        state.states = [];
      });
  },
});

// const { } = userSlice.actions;
export default paymenetSlice.reducer;
