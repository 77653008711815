import colors from "assets/theme/base/colors";
import bgAdmin from "assets/images/body-background.png";

const { info, dark, white } = colors;
export default {
  html: {
    scrollBehavior: "smooth",
    background: dark.body,
  },
  body: {
    background: `url(${bgAdmin})`,
    backgroundSize: "cover",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },

  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".MuiPaginationItem-root": {
    color: `white !important`,
  },
  ".css-1h3mp62-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
    // backgroundColor: "#1a1f37 !important",
    backgroundColor: "#a5a6aa !important",
    // border: '0.5px solid gray !important',
  },
  'div#quill-message-box': {
    background: '#0f1535',
    color: 'white'
},
'.quill-editor .ql-container': {
  minHeight: '120px', /* Approximate height for 4 rows */
  overFlowY: 'auto'  /* Add scroll when content exceeds */
},
".quill-editor .ql-editor": {
  minHeight: '120px', /* Matches container height */
  lineHeight: '1.5'  /* Adjust line spacing */
}

};
