import { createSlice } from "@reduxjs/toolkit";

const netGameSlice = createSlice({
  name: "game",
  initialState: {
    game: {},
  },
  reducers: {
    getNetGamesItems: (state, action) => {
      state.game = action.payload;
    },
  },
});

export const { getNetGamesItems } = netGameSlice.actions;
export default netGameSlice.reducer;
