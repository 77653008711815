import storage from "services/storage";
import http, { httpForm } from "./http-common";


const getSettings = () => {
  return http.get(`/settings/general`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const updateSettings = (data) => {
  return http.put(
    `/settings/general`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};


const SettingsServices = {
  getSettings,
  updateSettings
};

export default SettingsServices;