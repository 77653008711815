import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Toast from "components/Toastify/Toastify";
import JackpotServices from "store/api/jackpotServices";
import storage from "./../../services/storage";
import SocketService from "services/socket.service";

const initialState = {
  all: [],
  totalCount: 0,
  totalPages: 1,
  totalPagesWinners: 1,
  totalCountWinners: 0,
  active: {},
  winners: [],
  status: "idle",
  statusActive: "idle",
  winnersStatus: "idle",
};

export const retriveAllJackpot = createAsyncThunk("getAll", async ({data,  history }) => {
  const response = await JackpotServices.getAll(data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }
  return response.data;
});
export const retriveActiveJackpot = createAsyncThunk("getActive", async ({ history }) => {
  const response = await JackpotServices.getActive();
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }

  return response.data;
});
export const retriveJackpotWinners = createAsyncThunk("getWinners", async ({ id, data, history }) => {
  const response = await JackpotServices.getWinners(id, data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }

  return response.data;
});

export const triggerJackpot = createAsyncThunk("trigger", async ({ data, history }) => {
  const response = await JackpotServices.trigger(data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return response.data;
});

export const resetJackpot = createAsyncThunk("reset", async ({ data, history }) => {
  const response = await JackpotServices.reset(data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }

  return response.data;
});

export const resetJackpot20 = createAsyncThunk("reset20", async ({ data, history }) => {
  const response = await JackpotServices.reset20(data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }
  if(response.data.success === false){
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }else{
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return response.data;
});

export const resetJackpot500 = createAsyncThunk("reset500", async ({ data, history }) => {
  const response = await JackpotServices.reset500(data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }

  return response.data;
});



const jackpotSlice = createSlice({
  name: "jackpot",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(retriveAllJackpot.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(retriveAllJackpot.fulfilled, (state, action) => {
        state.all = action.payload?.jackpots || [];
        state.totalCount = action.payload?.totalCount || 0;
        state.totalPages = action.payload?.totalPages || 1;
        state.status = "fulfilled";
      }),
      builder.addCase(retriveAllJackpot.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(retriveActiveJackpot.pending, (state, action) => {
        state.statusActive = "pending";
      }),
      builder.addCase(retriveActiveJackpot.fulfilled, (state, action) => {
        state.active = action.payload.jackpot;
        state.statusActive = "fulfilled";
      }),
      builder.addCase(retriveActiveJackpot.rejected, (state, action) => {
        state.statusActive = "rejected";
      }),
      builder.addCase(retriveJackpotWinners.pending, (state, action) => {
        state.winnersStatus = "pending";
      }),
      builder.addCase(retriveJackpotWinners.fulfilled, (state, action) => {
        state.winners = action.payload?.jackpotWinners || [];
        state.totalCountWinners = action.payload?.totalCount || 0;
        state.totalPagesWinners = action.payload?.totalPages || 1;
        state.winnersStatus = "fulfilled";
      }),
      builder.addCase(retriveJackpotWinners.rejected, (state, action) => {
        state.winnersStatus = "rejected";
      }),
      builder.addCase(triggerJackpot.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(triggerJackpot.fulfilled, (state, action) => {
        const newItem = action.payload?.jackpot;
        state.active = newItem;
        state.all.unshift(newItem);
        state.status = "fulfilled";
      }),
      builder.addCase(triggerJackpot.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(resetJackpot.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(resetJackpot.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          const oldJackpot = action.payload.jackpot;
          const newJackpot = action.payload.newJackpot;
          state.active = newJackpot || null;
          const index = state.all.findIndex((item) => item?._id === oldJackpot?._id);
          if (index >= 0) state.all[index] = { ...state.all[index], ...oldJackpot };
          state.all.unshift(newJackpot);
          Toast.success(action.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          Toast.error(action.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        state.status = "fulfilled";
      }),
      builder.addCase(resetJackpot.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(resetJackpot20.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(resetJackpot20.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          const oldJackpot = action.payload.jackpot;
          state.all.unshift(oldJackpot);
         
        } 
        state.status = "fulfilled";
      }),
      builder.addCase(resetJackpot20.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(resetJackpot500.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(resetJackpot500.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          const oldJackpot = action.payload.jackpot;
          state.all.unshift(oldJackpot);
          Toast.success(action.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } else {
          Toast.error(action.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        
        state.status = "fulfilled";
      }),
      builder.addCase(resetJackpot500.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export default jackpotSlice.reducer;
