import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Toast from "components/Toastify/Toastify";
import JackpotServices from "store/api/jackpotServices";
import storage from "./../../services/storage";
import SocketService from "services/socket.service";
import PromotionServices from "store/api/promotionServices";

const initialState = {
  all: [],
  schedule: [],
  totalCount: 0,
  totalPages: 1,
  totalPagesWinners: 1,
  totalCountWinners: 0,
  active: {},
  winners: [],
  status: "idle",
  statusActive: "idle",
  closeStatus: "idle",
  winnersStatus: "idle",
  scheduleStatus: "idle",
};

export const retriveAllPromotions = createAsyncThunk("getAll", async ({ data, history }) => {
  const { perPage, page } = data;
  const response = await PromotionServices.getAll(perPage, page);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }
  return response.data;
});

export const retriveActivePromotion = createAsyncThunk("getActive", async ({ history }) => {
  const response = await PromotionServices.getActive();
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }

  return response.data;
});
export const retriveJackpotWinners = createAsyncThunk(
  "getWinners",
  async ({ id, data, history }) => {
    const response = await JackpotServices.getWinners(id, data);
    if (
      (response.data.success === false &&
        response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      response.data.message === "Sorry! Services not available in your country." ||
      response.data.message === "Sorry! Services not available in your region." ||
      response.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      Toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/signin");
    }

    return response.data;
  }
);

export const createPromotion = createAsyncThunk(
  "createPromotion",
  async ({ data, getAllData, history }) => {
    const { perPage, page } = getAllData;
    const response = await PromotionServices.createPromotion(data);
    if (
      (response.data.success === false &&
        response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      response.data.message === "Sorry! Services not available in your country." ||
      response.data.message === "Sorry! Services not available in your region." ||
      response.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      Toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/signin");
    } else if (response.data.success === false) {
      Toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (response.data.success === true) {
      Toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      const responseAll = await PromotionServices.getAll(perPage, page);

      let returnData = {
        newData: response.data,
        all: responseAll.data,
      };
      return returnData;
    } else {
      Toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    // return response.data;
  }
);

export const closePromotion = createAsyncThunk("reset", async ({ id, getAllData, history }) => {
  const { perPage, page } = getAllData;
  const response = await PromotionServices.deactivate(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    const responseAll = await PromotionServices.getAll(perPage, page);
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return responseAll.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

export const retriveSchedulePromotions = createAsyncThunk("getschedule", async ({ history }) => {
  const response = await PromotionServices.getSchedulelisting();
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }

  return response.data;
});

export const createSchedulePromotion = createAsyncThunk(
  "createSchedulePromotion",
  async ({ data, getAllData, history }) => {
    const { perPage, page } = getAllData;
    const response = await PromotionServices.schedulePromotion(data);
    if (
      (response.data.success === false &&
        response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      response.data.message === "Sorry! Services not available in your country." ||
      response.data.message === "Sorry! Services not available in your region." ||
      response.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      Toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/signin");
    } else if (response.data.success === false) {
      Toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (response.data.success === true) {
      Toast.success("Promotion scheduled successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      const responseAll = await PromotionServices.getSchedulelisting();

      let returnData = {
        newData: response.data,
        all: responseAll.data,
      };
      return returnData;
    } else {
      Toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    // return response.data;
  }
);

export const deletePromotion = createAsyncThunk("deletePromotion", async ({ id, history }) => {
  const response = await PromotionServices.deleteSchedulePromotion(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return { id };
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

export const pausePromotion = createAsyncThunk("pausePromotion", async ({ id, history }) => {
  const response = await PromotionServices.pauseSchedulePromotion(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

export const resumePromotion = createAsyncThunk("resumePromotion", async ({ id, history }) => {
  const response = await PromotionServices.resumeSchedulePromotion(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

export const updateSchedulePromotion = createAsyncThunk("updatePromotion", async ({ id, data, history }) => {
  const response = await PromotionServices.updateSchedulePromotion(id, data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

const promotionsSlice = createSlice({
  name: "jackpot",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(retriveAllPromotions.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(retriveAllPromotions.fulfilled, (state, action) => {
        state.all = action.payload?.promotions || [];
        state.totalCount = action.payload?.totalCount || 0;
        state.totalPages = action.payload?.totalPages || 1;
        state.status = "fulfilled";
      }),
      builder.addCase(retriveAllPromotions.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(retriveActivePromotion.pending, (state, action) => {
        state.statusActive = "pending";
      }),
      builder.addCase(retriveActivePromotion.fulfilled, (state, action) => {
        state.active = action.payload.promotion;
        state.statusActive = "fulfilled";
      }),
      builder.addCase(retriveActivePromotion.rejected, (state, action) => {
        state.statusActive = "rejected";
      }),
      builder.addCase(retriveJackpotWinners.pending, (state, action) => {
        state.winnersStatus = "pending";
      }),
      builder.addCase(retriveJackpotWinners.fulfilled, (state, action) => {
        state.winners = action.payload?.jackpotWinners || [];
        state.totalCountWinners = action.payload?.totalCount || 0;
        state.totalPagesWinners = action.payload?.totalPages || 1;
        state.winnersStatus = "fulfilled";
      }),
      builder.addCase(retriveJackpotWinners.rejected, (state, action) => {
        state.winnersStatus = "rejected";
      }),
      builder.addCase(createPromotion.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(createPromotion.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.all = action.payload?.all.promotions || [];
          state.totalCount = action.payload?.all.totalCount || 0;
          state.totalPages = action.payload?.all.totalPages || 1;
          state.active = action.payload?.newData.promotion;
        }
        state.status = "fulfilled";
      }),
      builder.addCase(createPromotion.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(closePromotion.pending, (state, action) => {
        state.status = "pending";
        state.closeStatus = "pending";
      }),
      builder.addCase(closePromotion.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.all = action.payload?.promotions || [];
          state.active = null;
          state.totalCount = action.payload?.totalCount || 0;
          state.totalPages = action.payload?.totalPages || 1;
        }
        state.status = "fulfilled";
      }),
      builder.addCase(closePromotion.rejected, (state, action) => {
        state.status = "rejected";
        state.closeStatus = "fulfilled";
      }),
      builder.addCase(retriveSchedulePromotions.pending, (state, action) => {
        state.scheduleStatus = "pending";
      }),
      builder.addCase(retriveSchedulePromotions.fulfilled, (state, action) => {
        state.schedule = action.payload?.schedules || [];
        state.scheduleStatus = "fulfilled";
      }),
      builder.addCase(retriveSchedulePromotions.rejected, (state, action) => {
        state.scheduleStatus = "rejected";
      }),
      builder.addCase(createSchedulePromotion.pending, (state, action) => {
        state.scheduleStatus = "pending";
      }),
      builder.addCase(createSchedulePromotion.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.schedule = action.payload?.all.schedules || [];
        }
        state.scheduleStatus = "fulfilled";
      }),
      builder.addCase(createSchedulePromotion.rejected, (state, action) => {
        state.scheduleStatus = "rejected";
      }),
      builder.addCase(deletePromotion.pending, (state, action) => {
        state.scheduleStatus = "pending";
      }),
      builder.addCase(deletePromotion.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          const index = state.schedule.findIndex((item) => item._id === action.payload.id);
          state.schedule.splice(index, 1);
        }
        state.scheduleStatus = "fulfilled";
      }),
      builder.addCase(deletePromotion.rejected, (state, action) => {
        state.scheduleStatus = "rejected";
      }),
      builder.addCase(pausePromotion.pending, (state, action) => {
        state.scheduleStatus = "pending";
      }),
      builder.addCase(pausePromotion.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          const index = state.schedule.findIndex((item) => item?._id === action.payload?.schedule?._id);
          state.schedule[index] = action.payload?.schedule
         
        }
        state.scheduleStatus = "fulfilled";
      }),
      builder.addCase(pausePromotion.rejected, (state, action) => {
        state.scheduleStatus = "rejected";
      }),
      builder.addCase(resumePromotion.pending, (state, action) => {
        state.scheduleStatus = "pending";
      }),
      builder.addCase(resumePromotion.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          const index = state.schedule.findIndex((item) => item?._id === action.payload?.scheduledPromotion?._id);

          state.schedule[index] = action.payload?.scheduledPromotion
         
        }
        state.scheduleStatus = "fulfilled";
      }),
      builder.addCase(resumePromotion.rejected, (state, action) => {
        state.scheduleStatus = "rejected";
      }),
      builder.addCase(updateSchedulePromotion.pending, (state, action) => {
        state.scheduleStatus = "pending";
      }),
      builder.addCase(updateSchedulePromotion.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          const index = state.schedule.findIndex((item) => item?._id === action.payload?.schedule?._id);

          state.schedule[index] = action.payload?.schedule
         
        }
        state.scheduleStatus = "fulfilled";
      }),
      builder.addCase(updateSchedulePromotion.rejected, (state, action) => {
        state.scheduleStatus = "rejected";
      });
  },
});

export default promotionsSlice.reducer;
