import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import authReducer from "../store/auth/authState";
import authUser from "../store/auth/user";
import loginUi from "../store/ui/loginUi";
import userData from "../store/ui/userData";
import { setEditUser } from "./auth/eidtProfile";
import eidtUserSlice from "../store/auth/eidtProfile";
import usersListSlice from "../store/auth/usersList";
import singleUserReducer from "../store/slices/singleUserSlice";
import productsReducer from "./slices/productsSlice";
import giftCardsReducer from "./slices/giftCardSlice";
import partnersReducer from "./slices/partnerSlice";
import singlePartnerReducer from "./slices/singlePartnerSlice";
import gameSlice from "./ui/gamesUi";
import dashboardReducer from "./slices/dashboardSlice";
import netGameSlice from "./ui/netGamesUi";
import jackpotSlice from "./slices/jackpotSlice";
import paymentsSlice from "./slices/paymentSlice";
import settingsSlice from "./slices/settingsSlice";
import notificationSlice from "./slices/notificationSlice";
import affiliateArtists from "./ui/artistSlice";
import promotionsSlice from "./slices/promotionsSlice";
import couponsSlice from "./slices/couponsSlice"
import campaignSlice from "./slices/campaignSlice"
import wheelPrizesSlice from "./slices/wheelPrizesSlice"

// import getUsersList from "../store/allUsers"
const combineReducer = combineReducers({
  auth: authReducer,
  user: authUser,
  login: loginUi,
  userData: userData,
  editUser: eidtUserSlice,
  usersList: usersListSlice,
  singleUser: singleUserReducer,
  products: productsReducer,
  giftCards: giftCardsReducer,
  partners: partnersReducer,
  singlePartner: singlePartnerReducer,
  game: gameSlice,
  netGame: netGameSlice,
  dashboard: dashboardReducer,
  jackpot: jackpotSlice,
  payments: paymentsSlice,
  settings: settingsSlice,
  notification: notificationSlice,
  affiliateArtists: affiliateArtists,
  promotions: promotionsSlice,
  coupons: couponsSlice,
  prizes: wheelPrizesSlice,
  campaign: campaignSlice,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
