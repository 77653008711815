import { useEffect } from "react";
import { useSelector } from "react-redux";
import SocketService from "../services/socket.service";

export default function SocketConnection() {
  const { _id, role } = useSelector((state) => state?.userData?.data) || {};
  useEffect(() => {
    SocketService.connect({ _id, role });

    return () => {
      SocketService.disconnect()
    };
  }, [_id, role]);

  return null;
}
