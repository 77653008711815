import storage from "services/storage";
import http from "./http-common";


const getAll = () => {
  return http.get(`/games/prizes`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};


const edit = (id, data) => {
  return http.put(
    `/games/prizes/${id}`,
    data,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getSpins = ({page, perPage, search, orderBy, sortBy, startDate, endDate}) => {
  return http.get(
    `/coupons/fortune-wheel?page=${page}&perPage=${perPage}&search=${search}&orderBy=${orderBy}&sortBy=${sortBy}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        "Content-type":  "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const WheelPrizesServices = {
  getAll,
  getSpins,
  edit,

 
}
export default WheelPrizesServices;
