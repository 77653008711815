import storage from "services/storage";
import http, { httpForm, newHttp } from "./http-common";

const get = (data) => {
  return http.post(`/partner/list`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getNotes = (id) => {
  return http.get(`partner/getpartnernotes/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const addNotes = (data) => {
  return http.post(`partner/addpartnernotes`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const deleteNotes = (userId, noteId) => {
  return http.delete(`partner/deletepartnernotes/${userId}?isDeleted=true&_id=${noteId}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getSinglePartner = ({ id }) => {
  return http.get(`/partner/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const getRefferal = ({ id }) => {
  return http.get(`/partner/referralusers/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const add = (data) => {
  return http.post("/user", data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const update = (id, data) => {
  return http.post(`/partner/editpartner/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const remove = ({ id }) => {
  return http.delete(`/partner/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const enable = (data) => {
  return http.patch(`/user`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const flag = (id, data) => {
  return http.put(`partner/isflagpartner/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const cashoutAllow = (id, data) => {
  return http.put(`partner/payouts/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const payout = (id, data) => {
  return http.put(`partner/payoutpercentage/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const parentpayout = (id, data) => {
  return http.put(`partner/parentpercentage/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const affiliateParent = (id, data) => {
  return http.put(`partner/assign-parent/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const userPurchased = (id) => {
  return http.get(`partner/totalpurchased/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const userCashout = (id) => {
  return http.get(`partner/referralusercashout/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const topGames = (id, dataGame) => {
  return http.post(`partner/referalusertopplayedgames/${id}`, dataGame, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const timePlayed = (id, data) => {
  return http.post(`partner/referralUserTotalTimePlayed/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const winLossRatio = (id, data) => {
  return http.post(`partner/referraluserwinlossratio/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getProfit = (id, data) => {
  return http.post(`partner/refferaluserprofits/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getRefferalLinks = (id) => {
  return http.get(`partner/referral/partner/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const updateRefferalLink = (id, data) => {
  return http.put(`partner/referral/${id}`, data,{
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const unverifiedUsers = (id, data) => {
  return http.post(`partner/unapproved-referrals/${id}`, data,{
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const makeParent = (id) => {
  return http.put(`partner/make-parentPartner/${id}`, {},{
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const PartnerServices = {
  get,
  add,
  remove,
  update,
  getSinglePartner,
  enable,
  flag,
  payout,
  parentpayout,
  affiliateParent,
  getNotes,
  addNotes,
  deleteNotes,
  getRefferal,
  userPurchased,
  userCashout,
  topGames,
  timePlayed,
  winLossRatio,
  getProfit,
  getRefferalLinks,
  updateRefferalLink,
  cashoutAllow,
  unverifiedUsers,
  makeParent,
};

export default PartnerServices;
