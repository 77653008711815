import http from "../../services/http";
import { handleErrors } from "../../helpers/error";
import http2 from "./http-common";
import storage from "../../services/storage";
import { getGamesItems } from "store/ui/gamesUi";
import Toast from "components/Toastify/Toastify";
import SocketService from "services/socket.service";

const path = "games/evoplay";
const path2 = "games/";
const hottoday = "games/add-hotgames";
const slot = "games/add-slot";
const popular = "games/add-popular";
const jackpot = "games/add-jackpot";
const active = "games/active";
export const catHotToday = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.put({
        url: hottoday,
        body,

        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const catSlot = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.put({
        url: slot,
        body,

        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const catPopular = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.put({
        url: popular,
        body,

        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const catJackpot = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.put({
        url: jackpot,
        body,

        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const GameIsActive = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.put({
        url: active,
        body,

        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const GetGamesList = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        url: path,
        body,

        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);

      if (res.data.success === true) {
        dispatch(getGamesItems(res.data));
      }
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const GetSingleGame = ({ id = "", history, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: path2 + id,

        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);

      if (res.data.success === true) {
        // dispatch(getGamesItems(res.data));
      } else {
        if (
          res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
          res.data.message === "Sorry! Services not available in your country." ||
          res.data.message === "Sorry! Services not available in your region." ||
          res.data.message === "You cannot use LionsDen with a VPN or Proxy."
        ) {
          storage.remove("xAuthToken");
          storage.remove("user");
          SocketService.disconnect();
          history.push("/authentication/sign-in");
          Toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    } catch (error) {
      if (onError) onError(error);
      else handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

const getGameStats = (data, gameId) => {
  return http2.post(
    `/games/${gameId}/graph/bet-overview`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getGameActiveUser = (data, gameId) => {
  return http2.post(
    `/games/${gameId}/graph/players`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getGameActiveUserNow = (data, gameId) => {
  return http2.post(
    `/games/${gameId}/live/players`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getGameNewUsers = (data, gameId) => {
  return http2.post(
    `/games/${gameId}/graph/new-players`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const GameServices = {
  getGameStats,
  getGameActiveUser,
  getGameActiveUserNow,
  getGameNewUsers,
};
export default GameServices;
