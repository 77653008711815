import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Toast from "components/Toastify/Toastify";
import ProductServices from "store/api/products";
import storage from "./../../services/storage";
import SocketService from "services/socket.service";

const initialState = {
  status: "idle",
  data: [],
  sub: [],
  tracks: [],
};

export const addProduct = createAsyncThunk("product/add", async ({ data, history }) => {
  const res = await ProductServices.addProduct(data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/products");
  } else {
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  return res.data.data;
});

export const addProductTrack = createAsyncThunk(
  "product/addtrack",
  async ({ data, id, history }) => {
    const res = await ProductServices.addProductTrack(data, id);
    if (res.data.success === true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      history.push(`/products/detail/${id}`);
    } else {
      if (
        (res.data.success == false &&
          res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
        res.data.message === "Sorry! Services not available in your country." ||
        res.data.message === "Sorry! Services not available in your region." ||
        res.data.message === "You cannot use LionsDen with a VPN or Proxy."
      ) {
        storage.remove("xAuthToken");
        storage.remove("user");
        SocketService.disconnect();
        history.push("/authentication/sign-in");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    return res.data;
  }
);

export const delProductTrack = createAsyncThunk(
  "product/deltrack",
  async ({ data, id, history }) => {
    const res = await ProductServices.delProductTrack(data, id);
    if (res.data.success === true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      // history.push(`/products/detail/${id}`);
    } else {
      if (
        (res.data.success == false &&
          res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
        res.data.message === "Sorry! Services not available in your country." ||
        res.data.message === "Sorry! Services not available in your region." ||
        res.data.message === "You cannot use LionsDen with a VPN or Proxy."
      ) {
        storage.remove("xAuthToken");
        storage.remove("user");
        SocketService.disconnect();
        history.push("/authentication/sign-in");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    // let rtrnData = [data.trackId, id, res.data]
    return res.data.data;
  }
);

export const addSubProduct = createAsyncThunk("product/sub-add", async ({ id, data, history }) => {
  const res = await ProductServices.addSubProduct(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push(`/products/detail/${id}`);
  } else {
    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  return res.data.data;
});

export const updateProduct = createAsyncThunk("product/update", async ({ id, data, history }) => {
  const res = await ProductServices.update(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/products");
  } else {
    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  return res.data.data;
});

export const updateSubProduct = createAsyncThunk(
  "product/sub-update",
  async ({ subId, data, history, id }) => {
    const res = await ProductServices.updateSub(subId, data);
    if (res.data.success === true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      history.push(`/products/detail/${id}`);
    } else {
      if (
        res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
      ) {
        storage.remove("xAuthToken");
        storage.remove("user");
        SocketService.disconnect();
        history.push("/authentication/sign-in");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    return res.data.data;
  }
);

export const retriveProducts = createAsyncThunk("products/get", async ({ history }) => {
  try {
    const res = await ProductServices.getProducts();
    return res.data.data;
  } catch (error) {
    if (
      error.response.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      error.response.data.message === "Sorry! Services not available in your country." ||
      error.response.data.message === "Sorry! Services not available in your region." ||
      error.response.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      Toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
    }
  }
});
export const retriveSubProducts = createAsyncThunk("products/sub-get", async ({ history, id }) => {
  const res = await ProductServices.getSubProducts(id);
  if (
    res.data.success === false &&
    res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
  } else {
    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  return res.data.data;
});
export const deleteProduct = createAsyncThunk("products/delete", async ({ id, history }) => {
  const res = await ProductServices.remove({ id });
  if (res.data.success == true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  return { id };
});

export const deleteSubProduct = createAsyncThunk("products/subdelete", async ({ _id, history }) => {
  const res = await ProductServices.removeSub(_id);
  if (res.data.success == true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  return { _id };
});

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    removeProduct: (state, action) => {
      const index = state.data.data.findIndex((item) => item._id === action.payload);
      state.data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProduct.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(addProduct.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(addSubProduct.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(addSubProduct.fulfilled, (state, action) => {
        state.sub.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(addProductTrack.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(addProductTrack.fulfilled, (state, action) => {
        state.tracks?.push(action.payload.data);
        state.status = "fulfilled";
      }),
      builder.addCase(updateProduct.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(updateProduct.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item?._id === action.payload?._id);
        state.data[index] = {
          ...state.data[index],
          ...action.payload,
        };
        // state.data.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(updateSubProduct.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(updateSubProduct.fulfilled, (state, action) => {
        const index = state.sub.findIndex((item) => item?._id === action.payload?._id);
        state.sub[index] = {
          ...state.sub[index],
          ...action.payload,
        };
        // state.data.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(retriveProducts.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(retriveProducts.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "fulfilled";
      }),
      builder.addCase(retriveSubProducts.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(retriveSubProducts.fulfilled, (state, action) => {
        state.sub = action.payload;
        state.status = "fulfilled";
      }),
      builder.addCase(deleteProduct.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(deleteProduct.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item._id === action.payload.id);
        state.data.splice(index, 1);
        state.status = "fulfilled";
      }),
      builder.addCase(deleteSubProduct.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(deleteSubProduct.fulfilled, (state, action) => {
        const index = state.sub.findIndex((item) => item._id === action.payload._id);
        state.sub.splice(index, 1);
        state.status = "fulfilled";
      }),
      builder.addCase(delProductTrack.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(delProductTrack.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item?._id === action.payload?._id);
        state.data[index] = {
          ...state.data[index],
          ...action.payload,
        };
        state.status = "fulfilled";
      });
  },
});

export const { removeProduct } = productSlice.actions;
export default productSlice.reducer;
