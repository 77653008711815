import { io } from "socket.io-client";
import { baseUri } from "../config";
import storage from "./storage";

let socket = null;

const SocketService = {
  connect({ _id, role }) {
    if (socket) socket.disconnect();

    const token = storage.get("xAuthToken");
    if (!token || role !== "ADMIN") return;

    const query = `/?_id=${_id}&role=${role}`;
    socket = io(`${baseUri}${query}`, {
      path: "/socket.io/",
      transports: ["websocket", "polling"],
    });

    socket.on("connect", (...data) => {
      // console.log("Socket connected:", _id, data, socket);
    });

    socket.on("onAny", (...data) => {
      // console.log("Socket onAny:", data, socket);
    });

    socket.on("disconnect", (...data) => {
      // console.log("Socket disconnected:", _id, data, socket);
    });

    socket.on("connect_error", (...error) => {
      // console.log("Socket connect_error", error, socket);
    });
  },

  disconnect() {
    if (socket) socket.disconnect();
  },
};

export default SocketService;
