import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProfileServices from "store/api/profile";
import storage from "services/storage";
import Toast from "components/Toastify/Toastify";
import SocketService from "services/socket.service";

export const retriveProfile = createAsyncThunk("profile/get", async ({ history }) => {
  const res = await ProfileServices.getProfile();
  if (
    res.data.success == false &&
    res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data.user;

  // try {
  //   const res = await ProfileServices.getProfile();
  //   return res.data.user
  // } catch (error) {
  //   if(error.response.data.message === 'Your Session has been expired, Kindly sign in to continue.'){

  //     Toast.error(error.response.data.message, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         });
  //     storage.remove("xAuthToken");
  //   storage.remove("user");
  //   SocketService.disconnect();
  //   history.push("/authentication/sign-in");
  //   }
  // }
});

const userData = createSlice({
  name: "userData",
  initialState: {
    value: "",
    status: "idle",
    data: {},
  },
  reducers: {
    updateValue: (state, action) => {
      state.value = action.payload;
    },
    resetValue: (state, action) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retriveProfile.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(retriveProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "fulfilled";
      });
  },
});

export const { updateValue, resetValue } = userData.actions;
export default userData.reducer;