import storage from "services/storage";
import http from "./http-common";

const getActive = () => {
  return http.get(`/promotions/active`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getAll = (perPage, page) => {
  return http.get(`/promotions?page=${page}&perPage=${perPage}`,  {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const getSchedulelisting = () => {
  return http.get(`/promotions/schedules`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const createPromotion = (data) => {
  return http.post(`/promotions`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const schedulePromotion = (data) => {
  return http.post(`/promotions/schedules
`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const updateSchedulePromotion = (id, data) => {
  return http.put(`/promotions/schedules/${id}
`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const pauseSchedulePromotion = (id) => {
  return http.put(`/promotions/schedules/${id}/pause
`, {}, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const resumeSchedulePromotion = (id) => {
  return http.put(`/promotions/schedules/${id}/resume
`, {}, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const deleteSchedulePromotion = (id) => {
  return http.delete(`/promotions/schedules/${id}
`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const deactivate = (id) => {
  return http.put(`/promotions/${id}/deactivate`, {}, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const PromotionServices = {
  getActive,
  getAll,
  createPromotion,
  deactivate,
  getSchedulelisting,
  schedulePromotion,
  updateSchedulePromotion,
  pauseSchedulePromotion,
  resumeSchedulePromotion,
  deleteSchedulePromotion,
  // reset500,
};

export default PromotionServices;
