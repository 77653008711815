import storage from "services/storage";
import http from "./http-common";


const getAll = ({page, perPage}) => {
  return http.get(`/referral-campaigns?page=${page}&perPage=${perPage}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const create = (data) => {
  return http.post(
    `/referral-campaigns`,
    data,
    {
      headers: {
        "Content-type":  "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getSingle = (id) => {
  return http.get(
    `/referral-campaigns/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const edit = (id, data) => {
  return http.put(
    `/referral-campaigns/${id}`,
    data,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const remove = (id, data) => {
  return http.delete(
    `/referral-campaigns/${id}`,
    data,
    {
      headers: {
        "Content-type": "multipart/form-data",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const expire = (id) => {
  return http.put(
    `/referral-campaigns/${id}/end`,
    {},
    {
      headers: {
        "Content-type": "multipart/form-data",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};
const CampaignServices = {
  getAll,
  create,
  getSingle,
  remove,
  edit,
  expire
}
export default CampaignServices;
