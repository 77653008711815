import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "services/storage";
import Toast from "components/Toastify/Toastify";
import GameServices from "store/api/games";
import { formatNumber } from "services/number.service";

export const retriveGameStats = createAsyncThunk(
  "retrive/GameStats",
  async ({ data, gameId }, history) => {
    const res = await GameServices.getGameStats(data, gameId);
    if (res.data.success === true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const retriveActiveUserGame = createAsyncThunk(
  "retrive/ActiveUserGame",
  async ({ data, gameId }, history) => {
    const res = await GameServices.getGameActiveUser(data, gameId);

    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);
export const retriveNewUserGame = createAsyncThunk(
  "retrive/NewUserGame",
  async ({ data, gameId }, history) => {
    const res = await GameServices.getGameNewUsers(data, gameId);

    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);
export const retriveActiveUserGameNow = createAsyncThunk(
  "retrive/ActiveUserGameNow",
  async ({ data, gameId }, history) => {
    const res = await GameServices.getGameActiveUserNow(data, gameId);

    if (
      res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue." ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

const gameSlice = createSlice({
  name: "game",
  initialState: {
    game: {},
    wins: [],
    bets: [],
    dates: [],
    betCount: 0,
    winCount: 0,
    activeDates: [],
    activeUsers: [],
    activeNow: 0,
    newUsersCount: [],
    newUsersDate: [],
    status: "idle",
    activeUserStatus: "idle",
    activeNowStatus: "idle",
    newUsersStatus: "idle",
  },
  reducers: {
    getGamesItems: (state, action) => {
      state.game = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retriveGameStats.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(retriveGameStats.fulfilled, (state, action) => {
        let data = action.payload.winnings;
        const sortedData = data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        const newData = [];
        let betCount = 0;
        let winCount = 0;

        for (let i = 0; i < data?.length; i++) {
          if (data[i].name === "bet") {
            betCount++;
          } else if (data[i].name === "win") {
            winCount++;
          }
        }
        state.betCount = betCount;
        state.winCount = winCount;
        sortedData.forEach((item) => {
          const itemDate = new Date(item.createdAt).toLocaleDateString(); // Extract the date

          // Check if the date already exists in newData
          const existingEntryIndex = newData.findIndex((entry) => entry.date === itemDate);

          if (existingEntryIndex !== -1) {
            // Date entry already exists, update winAmount and betAmount
            newData[existingEntryIndex].winAmount += item.win_amount || 0;
            newData[existingEntryIndex].betAmount += item.bet_amount || 0;
          } else {
            newData.push({
              date: itemDate,
              winAmount: item.win_amount || 0,
              betAmount: item.bet_amount || 0,
            });
          }
        });
        const winArray = newData?.map((item) => Number(formatNumber(item.winAmount)));
        const betArray = newData?.map((item) => Number(formatNumber(item.betAmount)));
        const dateArray = newData?.map((item) => item.date);
        state.wins = winArray;
        state.bets = betArray;
        state.dates = dateArray;
        state.status = "fullfilled";
      }),
      builder.addCase(retriveGameStats.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(retriveActiveUserGame.pending, (state, action) => {
        state.activeUserStatus = "pending";
      }),
      builder.addCase(retriveActiveUserGame.fulfilled, (state, action) => {
        let data = action.payload?.data;
        state.activeUsers = data?.map((item) => item.activeUsers);
        state.activeDates = data?.map((item) => item.date);
        state.activeUserStatus = "fullfilled";
      }),
      builder.addCase(retriveActiveUserGame.rejected, (state, action) => {
        state.activeUserStatus = "rejected";
      }),
      builder.addCase(retriveActiveUserGameNow.pending, (state, action) => {
        state.activeNowStatus = "pending";
      }),
      builder.addCase(retriveActiveUserGameNow.fulfilled, (state, action) => {
        state.activeNow = action.payload?.data;
        state.activeNowStatus = "fullfilled";
      }),
      builder.addCase(retriveActiveUserGameNow.rejected, (state, action) => {
        state.activeNowStatus = "rejected";
      }),
      builder.addCase(retriveNewUserGame.pending, (state, action) => {
        state.newUsersStatus = "pending";
      }),
      builder.addCase(retriveNewUserGame.fulfilled, (state, action) => {
        let data = action.payload?.data;
        state.newUsersCount = data?.map((item) => item.activeUsers);
        state.newUsersDate = data?.map((item) => item.date);
        state.newUsersStatus = "fullfilled";
      }),
      builder.addCase(retriveNewUserGame.rejected, (state, action) => {
        state.newUsersStatus = "rejected";
      });
  },
});

export const { getGamesItems } = gameSlice.actions;
export default gameSlice.reducer;
