import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SingleUserService from "./../api/singleUser";
import PartnerServices from "store/api/partnersServices";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import DashboardServices from "store/api/dashboard";
import storage from "services/storage";
// import NewUsersPerMonth from "layouts/dashboard/components/NewUsersMonthly";

const DateConverter = (d) => {
  const date = new Date(d).toLocaleDateString();
  return date;
};

const initialState = {
  active: {},
  newUsers: {
    options: [],
    data: [],
  },
  newUsersPerMonth: [],
  activeUsersByRegion: {
    options: [],
    data: [],
  },
  failedSignUpByRegion: {
    options: [],
    data: [],
  },
  activeUsers: [],
  topGames: [],
  spent: {
    options: [],
    data: [],
  },
  cashout: {
    options: [],
    data: [],
  },
  profit: [],
  allProfit: {},
  purchaseCashout: {},
  liveData: {},
  playingUserDetails: [],
  status: "idle",
  newUsersStaus: "idle",
  topGamesStaus: "idle",
  actUserStatus: "idle",
  spentStatus: "idle",
  cashoutStatus: "idle",
  profitStatus: "idle",
  allProfitStatus: "idle",
  liveDataStatus: "idle",
  newUsersPerMonthStatus: "idle",
  activeUsersByRegionStatus: "idle",
  failedSignUpByRegionStatus: "idle",
};

export const retriveActiveUsers = createAsyncThunk("activeUsers/retrieve", async ({ history }) => {
  const res = await DashboardServices.getActiveUser();
  res.data.myStatus = "a";

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    res.data.myStatus = "error";

    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});

export const retriveNewUsers = createAsyncThunk("retrive/newUser", async ({ data, history }) => {
  const res = await DashboardServices.getNewUser(data);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const getActiveUsersByDate = createAsyncThunk(
  "retrive/ActiveUsers",
  async ({ data, history }) => {
    const res = await DashboardServices.getActiveUsers(data);

    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data.data;
  }
);

export const getTopGamesByDate = createAsyncThunk(
  "retrive/topGames",
  async ({ data, limit, history }) => {
    const res = await DashboardServices.getTopGames(data, limit);

    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
    }
    return res.data;
  }
);

export const getBetsPerDay = createAsyncThunk("retrive/BetsPerDay", async ({ data, history }) => {
  const res = await DashboardServices.getBets(data);

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const getCashoutperDay = createAsyncThunk(
  "retrive/CashoutperDay",
  async ({ data, history }) => {
    const res = await DashboardServices.getCashout(data);

    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const getProfitAllProvider = createAsyncThunk(
  "retrive/ProfitperDay",
  async ({ data, history }) => {
    const res = await DashboardServices.getDailyProfitAllProviders(data);

    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      // Toast.error(res.data.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    }
    return res.data;
  }
);

export const getAllProfit = createAsyncThunk("retrive/getAllProfit", async ({ data, history }) => {
  const res = await DashboardServices.getAllProfit(data);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    // Toast.error(res.data.message, {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  }
  return res.data;
});

export const getPurchaseCashout = createAsyncThunk(
  "retrive/getPurchaseCashout",
  async ({ data, history }) => {
    const res = await DashboardServices.getTotalPurchase(data);

    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      // Toast.error(res.data.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "dark",
      // });
    }
    return res.data;
  }
);
export const getLiveData = createAsyncThunk("retrive/getLiveData", async ({ history }) => {
  const res = await DashboardServices.getliveData();

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
  }
  return res.data;
});

export const retriveNewUsersPerMonth = createAsyncThunk(
  "retrive/newUserPerMonth",
  async ({ data, history }) => {
    const { startDate, endDate } = data;
    const res = await DashboardServices.getNewUsersPerMonth({startDate, endDate});
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);
export const getUsersByRegion = createAsyncThunk(
  "retrive/getUsersByRegion",
  async ({ data, history }) => {
    const { startDate, endDate } = data;
    const res = await DashboardServices.getUsersByRegion({startDate, endDate});
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);


// failed sign up by state
export const getFailedSignUpByRegion = createAsyncThunk(
  "retrive/getFailedSignUpByRegion",
  async ({ data, history }) => {
    const { startDate, endDate } = data;
    const res = await DashboardServices.getFailedSignUpByRegion({startDate, endDate});
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);


const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    idleState: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retriveActiveUsers.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(retriveActiveUsers.fulfilled, (state, action) => {
        state.active = action.payload;
        state.status = action.payload.myStatus === "error" ? "rejected" : "fullfilled";
      }),
      builder.addCase(retriveActiveUsers.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(retriveNewUsers.pending, (state, action) => {
        state.newUsersStaus = "pending";
      }),
      builder.addCase(retriveNewUsers.fulfilled, (state, action) => {
        const temp = action.payload.data;
        const countArray = temp?.map((item) => item.count);
        const dateArray = temp?.map((item) => DateConverter(item.date));
        state.newUsers.options = countArray;
        state.newUsers.data = dateArray;
        state.newUsersStaus = "fullfilled";
      }),
      builder.addCase(retriveNewUsers.rejected, (state, action) => {
        state.newUsersStaus = "rejected";
      }),
      builder.addCase(getActiveUsersByDate.pending, (state, action) => {
        state.actUserStatus = "pending";
      }),
      builder.addCase(getActiveUsersByDate.fulfilled, (state, action) => {
        const data = action.payload;
        data?.sort((a, b) => new Date(a.date) - new Date(b.date));
        state.activeUsers = data;
        state.actUserStatus = "fullfilled";
      }),
      builder.addCase(getActiveUsersByDate.rejected, (state, action) => {
        state.actUserStatus = "rejected";
      }),
      builder.addCase(getTopGamesByDate.pending, (state, action) => {
        state.topGamesStaus = "pending";
      }),
      builder.addCase(getTopGamesByDate.fulfilled, (state, action) => {
        state.topGames = action.payload?.data || [];
        state.topGamesStaus = "fulfilled";
      }),
      builder.addCase(getTopGamesByDate.rejected, (state, action) => {
        state.topGames = [];
        state.topGamesStaus = "rejected";
      }),
      builder.addCase(getBetsPerDay.pending, (state, action) => {
        state.spentStatus = "pending";
      }),
      builder.addCase(getBetsPerDay.fulfilled, (state, action) => {
        const temp = action.payload.data;
        const countArray = temp?.map((item) => item.totalBetAmount);
        const dateArray = temp?.map((item) => DateConverter(item.day));
        state.spent.options = countArray;
        state.spent.data = dateArray;
        state.spentStatus = "fullfilled";
      }),
      builder.addCase(getBetsPerDay.rejected, (state, action) => {
        state.spentStatus = "rejected";
      }),
      builder.addCase(getCashoutperDay.pending, (state, action) => {
        state.cashoutStatus = "pending";
      }),
      builder.addCase(getCashoutperDay.fulfilled, (state, action) => {
        const temp = action.payload.data;
        const countArray = temp?.map((item) => item?.totalAmount.toFixed(2));
        const dateArray = temp?.map((item) => DateConverter(item.day));
        state.cashout.options = countArray;
        state.cashout.data = dateArray;
        state.cashoutStatus = "fullfilled";
      }),
      builder.addCase(getCashoutperDay.rejected, (state, action) => {
        state.cashoutStatus = "rejected";
      }),
      builder.addCase(getProfitAllProvider.pending, (state, action) => {
        state.profitStatus = "pending";
      }),
      builder.addCase(getProfitAllProvider.fulfilled, (state, action) => {
        state.profit = action.payload?.data || [];
        state.profitStatus = "fullfilled";
      }),
      builder.addCase(getProfitAllProvider.rejected, (state, action) => {
        state.profitStatus = "rejected";
      }),
      builder.addCase(getAllProfit.pending, (state, action) => {
        state.allProfitStatus = "pending";
      }),
      builder.addCase(getAllProfit.fulfilled, (state, action) => {
        state.allProfit = action.payload?.data || {};
        state.allProfitStatus = "fullfilled";
      }),
      builder.addCase(getAllProfit.rejected, (state, action) => {
        state.allProfitStatus = "rejected";
      }),
      builder.addCase(getLiveData.pending, (state, action) => {
        state.liveDataStatus = "pending";
      }),
      builder.addCase(getLiveData.fulfilled, (state, action) => {
        state.liveData = action.payload?.data || {};
        state.playingUserDetails = action.payload?.data?.playingUsersDetails || [];
        state.liveDataStatus = "fullfilled";
      }),
      builder.addCase(getLiveData.rejected, (state, action) => {
        state.liveData = {};
        state.playingUserDetails = [];
        state.liveDataStatus = "rejected";
      }),
      builder.addCase(retriveNewUsersPerMonth.pending, (state, action) => {
        state.newUsersPerMonthStatus = "pending";
      }),
        builder.addCase(retriveNewUsersPerMonth.fulfilled, (state, action) => {
          state.newUsersPerMonth = action.payload.data || [];
          state.newUsersPerMonthStatus = action.payload.myStatus === "error" ? "rejected" : "fullfilled";
        }),
        builder.addCase(retriveNewUsersPerMonth.rejected, (state, action) => {
          state.newUsersPerMonthStatus = "rejected";
        }),
        builder.addCase(getUsersByRegion.pending, (state, action) => {
          state.activeUsersByRegionStatus = "pending";
        }),
        builder.addCase(getUsersByRegion.fulfilled, (state, action) => {
          const temp = action.payload?.data;
          let sortedData = temp?.sort((a, b) => {
            if (a.region < b.region) return -1; // `a` comes before `b`
            if (a.region > b.region) return 1;  // `a` comes after `b`
            return 0; // `a` and `b` are equal
          });
          
          const usersArray = sortedData?.map((item) => item?.users);
          const regionArray = sortedData?.map((item) => String(item?.region) || "");
          state.activeUsersByRegion.options = regionArray;
          state.activeUsersByRegion.data = usersArray;
          state.activeUsersByRegionStatus = "fullfilled";
        }),
        builder.addCase(getUsersByRegion.rejected, (state, action) => {
          state.activeUsersByRegionStatus = "rejected";
        }),
        builder.addCase(getFailedSignUpByRegion.pending, (state, action) => {
          state.failedSignUpByRegionStatus = "pending";
        }),
        builder.addCase(getFailedSignUpByRegion.fulfilled, (state, action) => {
          const temp = action.payload?.blackListedSignups;
          let sortedData = temp?.sort((a, b) => {
            if (a.region < b.region) return -1; // `a` comes before `b`
            if (a.region > b.region) return 1;  // `a` comes after `b`
            return 0; // `a` and `b` are equal
          });
          
          const usersArray = sortedData?.map((item) => item?.failedSignups);
          const regionArray = sortedData?.map((item) => String(item?.region) || "");
          state.failedSignUpByRegion.options = regionArray;
          state.failedSignUpByRegion.data = usersArray;
          state.failedSignUpByRegionStatus = "fullfilled";
        }),
        builder.addCase(getFailedSignUpByRegion.rejected, (state, action) => {
          state.failedSignUpByRegionStatus = "rejected";
        });
  },
});

export const { idleState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
