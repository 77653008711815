import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";
import WheelPrizesServices from "store/api/wheelPrizesServices";


const initialState = {
  prizesList: [],
  spinsList: [],
  totalCountSpins: 0,
  totalPagesSpins: 1,
  prizesStatus: "idle",
  spinsStatus: "idle",
};

export const retrivePrizes = createAsyncThunk("allPrizes/retrieve", async ({history }) => {
  const res = await WheelPrizesServices.getAll();
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});


export const retriveSpins = createAsyncThunk("allSpins/retrieve", async ({data, history }) => {
  const {page, perPage, search, orderBy, sortBy, startDate, endDate} = data
  const res = await WheelPrizesServices.getSpins({page, perPage, search, orderBy, sortBy, startDate, endDate});
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});

export const editPrize = createAsyncThunk("editPrize", async ({ id, data, history }) => {
  const response = await WheelPrizesServices.edit(id, data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
   
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

const wheelPrizesSlice = createSlice({
  name: "prizes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(retrivePrizes.pending, (state, action) => {
      state.prizesStatus = "pending";
    }),
      builder.addCase(retrivePrizes.fulfilled, (state, action) => {
        state.prizesList = action.payload?.data || [];
       
        state.prizesStatus = "fullfilled";
      }),
      builder.addCase(retrivePrizes.rejected, (state, action) => {
        state.prizesStatus = "rejected";
        state.prizesList = [];
      }),
      
      builder.addCase(editPrize.pending, (state, action) => {
        state.prizesStatus = "pending";
      }),
      builder.addCase(editPrize.fulfilled, (state, action) => {
        const index = state.prizesList.findIndex((item) => item?._id === action.payload?.data?._id);
        state.prizesList[index] = action.payload?.data
        state.prizesStatus = "fullfilled";
      }),
      builder.addCase(editPrize.rejected, (state, action) => {
        state.prizesStatus = "rejected";
      }),
      builder.addCase(retriveSpins.pending, (state, action) => {
        state.spinsStatus = "pending";
      }),
        builder.addCase(retriveSpins.fulfilled, (state, action) => {
          state.spinsList = action.payload?.fortuneCoupons || [];
          state.totalCountSpins = action.payload?.totalCount;
          state.totalPagesSpins = action.payload?.totalPages;
         
          state.spinsStatus = "fullfilled";
        }),
        builder.addCase(retriveSpins.rejected, (state, action) => {
          state.spinsStatus = "rejected";
          state.spinsList = [];
        });
     
  },
});

export default wheelPrizesSlice.reducer;
