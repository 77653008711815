import React, { Suspense, lazy } from "react";
import AuthGuard from "guards/authGuard";
import ProtectGuard from "guards/protectGuard";
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
const Dashboard = lazy(() => lazyRetry(() => import("layouts/dashboard")));
const Profile = lazy(() => lazyRetry(() => import("layouts/profile")));
const SignIn = lazy(() => lazyRetry(() => import("layouts/authentication/sign-in")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("layouts/authentication/forgot-password"))
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./layouts/authentication/reset-password"))
);
const Users = lazy(() => lazyRetry(() => import("layouts/users")));
const User = lazy(() => lazyRetry(() => import("layouts/user")));
const AddUserPartner = lazy(() => lazyRetry(() => import("layouts/addUserPartner")));
const Products = lazy(() => lazyRetry(() => import("layouts/products")));
const AddProductForm = lazy(() => lazyRetry(() => import("layouts/products/AddProduct")));
const EditProductForm = lazy(() => lazyRetry(() => import("layouts/products/EditProduct")));
const AddSubProductForm = lazy(() => lazyRetry(() => import("layouts/products/AddSubProduct")));
const ProductDetail = lazy(() => lazyRetry(() => import("layouts/products/ProductDetail")));
const EditSubProductForm = lazy(() => lazyRetry(() => import("layouts/products/EditSubProduct")));
const Partners = lazy(() => lazyRetry(() => import("layouts/partners")));
const GamesPage = lazy(() => lazyRetry(() => import("layouts/games")));
const NetGamesPage = lazy(() => lazyRetry(() => import("layouts/netgames")));
const ArrowEdgeGamesListPage = lazy(() => lazyRetry(() => import("layouts/ArrowedgeGames")));
const KAGamesListPage = lazy(() => lazyRetry(() => import("layouts/KAGames")));
const LuckyStreakGamesListPage = lazy(() => lazyRetry(() => import("layouts/LuckyStreakGames")));
const AddTrackForm = lazy(() => lazyRetry(() => import("layouts/products/AddTrack")));
const AddPartner = lazy(() => lazyRetry(() => import("layouts/partners/AddPartner")));
const EditProfile = lazy(() => lazyRetry(() => import("layouts/profile/EditProfile")));
const ForgotPasswordNext = lazy(() =>
  lazyRetry(() => import("layouts/authentication/forgot-password-next"))
);
const PartnerDetail = lazy(() => lazyRetry(() => import("layouts/partners/PartnerDetail")));
const GameDetail = lazy(() => lazyRetry(() => import("layouts/games/GameDetail")));
const AddNewNotification = lazy(() => lazyRetry(() => import("layouts/Notification/AddNewNotification")));
const NetGameDetail = lazy(() => lazyRetry(() => import("layouts/netgames/GameDetail")));
// const GiftCards = lazy(() => lazyRetry(() => import("layouts/gift cards")));
// const GiftCardDetail = lazy(() => lazyRetry(() => import("layouts/gift cards/GiftCardDetail")));
// const AddGiftCard = lazy(() => lazyRetry(() => import("layouts/gift cards/AddGiftCard")));
// const EditGiftCard = lazy(() => lazyRetry(() => import("layouts/gift cards/EditGiftCard")));
// const AddSubGiftCardForm = lazy(() => lazyRetry(() => import("layouts/gift cards/AddSubGiftCard")));
// const EditSubGiftCardForm = lazy(() =>
//   lazyRetry(() => import("layouts/gift cards/EditSubGiftCard"))
// );
const EditPartner = lazy(() => lazyRetry(() => import("layouts/partners/EditPartner")));
const Jackpot = lazy(() => lazyRetry(() => import("layouts/jackpot")));
const JacpotDetail = lazy(() =>
  lazyRetry(() => import("layouts/jackpot/JackpotDetail/JacpotDetail"))
);
const Payments = lazy(() => lazyRetry(() => import("layouts/payments")));
const GeneralSettings = lazy(() => lazyRetry(() => import("layouts/generalSettings")));
const FailedLoginReport = lazy(() => lazyRetry(() => import("layouts/failedLogin")));
const FailedSignUpReport = lazy(() => lazyRetry(() => import("layouts/failedSignup")));
const Profits = lazy(() => lazyRetry(() => import("layouts/profits")));
const ActiveUsers = lazy(() => lazyRetry(() => import("layouts/ActiveUsers")));
const ActivePlayingUsers = lazy(() => lazyRetry(() => import("layouts/ActivePlayingUsers")));
const ReferalLinks = lazy(() => lazyRetry(() => import("layouts/partners/ReferalLinks")));
const UnverifiedUsers = lazy(() => lazyRetry(() => import("layouts/partners/UnverifiedUsers")));
const Transactions = lazy(() => lazyRetry(() => import("layouts/TransactionHistory")));
const CashoutHistory = lazy(() => lazyRetry(() => import("layouts/CashoutHistory")));
const UserTransactionDetails = lazy(() =>
  lazyRetry(() => import("layouts/user/UserTransactionDetails"))
);
const Notifcation = lazy(() => lazyRetry(() => import("layouts/Notification")));
const AffiliateArtist = lazy(() => lazyRetry(() => import("layouts/affiliateArtist")));
const AddAffiliateArtist = lazy(() => lazyRetry(() => import("layouts/addAffiliateArtist")));
const AffiliateRequests = lazy(() => lazyRetry(() => import("layouts/AffiliateRequests")));
const Promotions = lazy(() => lazyRetry(() => import("layouts/Promotions")));
const FriendListUser = lazy(() => lazyRetry(() => import("layouts/user/FriendListUser")));
const Coupons = lazy(() => lazyRetry(() => import("layouts/Coupons")));
const WheelPrizes = lazy(() => lazyRetry(() => import("layouts/FortuneWheelPrizes")));
const CreateCoupon = lazy(() => lazyRetry(() => import("layouts/Coupons/CreateCoupon")));
const EditCoupon = lazy(() => lazyRetry(() => import("layouts/Coupons/EditCoupon")));
const ReferralCampaign = lazy(() => lazyRetry(() => import("layouts/ReferralCampaign")));
const CreateCampaignPage = lazy(() => lazyRetry(() => import("layouts/ReferralCampaign/CreateCampaign")));
const EditCampaignPage = lazy(() => lazyRetry(() => import("layouts/ReferralCampaign/EditCampaign")));
const DetailCampaignPage = lazy(() => lazyRetry(() => import("layouts/ReferralCampaign/CampaignDetail")));


// icons
import { BsFillPersonFill } from "react-icons/bs";
import { IoHome, IoGameControllerSharp } from "react-icons/io5";
import { SiRiotgames } from "react-icons/si";
import { FaUsers, FaHandshake, FaTrophy, FaShoppingCart } from "react-icons/fa";
import { BiMoneyWithdraw, BiStore, BiWorld } from "react-icons/bi";
import { BsFillGearFill } from "react-icons/bs";
import { MdDiscount, MdVpnKey } from "react-icons/md";
import { FaBell } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { GiEdgedShield } from "react-icons/gi";
import { SiWegame } from "react-icons/si";
import { GiLuckyFisherman } from "react-icons/gi";
// import { AiFillGift } from "react-icons/ai";
import { GiPaintedPottery } from "react-icons/gi";
import { FaUserTimes } from "react-icons/fa";
import { SiReactivex } from "react-icons/si";
import { GiBabyfootPlayers } from "react-icons/gi";
import { GiGlowingArtifact } from "react-icons/gi";
import { RiCoupon2Fill } from "react-icons/ri";
import { GiCartwheel } from "react-icons/gi";
import { LuFerrisWheel } from "react-icons/lu";
import { MdCampaign } from "react-icons/md";
const routes = [
  {
    id: 1,
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: AuthGuard(Dashboard),
    noCollapse: true,
  },
  { id: 2, type: "title", title: "Users Pages", key: "users-pages" },
  {
    id: 3,
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <FaUsers size="15px" color="inherit" />,
    component: AuthGuard(Users),
    noCollapse: true,
  },
  {
    id: 4,
    key: "users",
    route: "/users/add",
    component: AuthGuard(AddUserPartner),
  },
  {
    id: 5,
    key: "users",
    route: "/users/:id",
    component: AuthGuard(User),
  },
  {
    id: 6,
    key: "users",
    route: "/users/ledger/:id",
    component: AuthGuard(UserTransactionDetails),
  },
  {
    id: 7,
    type: "collapse",
    name: "Partners",
    key: "partners",
    route: "/partners",
    icon: <FaHandshake size="15px" color="inherit" />,
    component: AuthGuard(Partners),
    noCollapse: true,
  },
  {
    id: 8,
    key: "partners",
    route: "/partners/add/:role",
    component: AuthGuard(AddPartner),
  },
  {
    id: 9,
    key: "partners",
    route: "/partners/:id",
    component: AuthGuard(PartnerDetail),
  },
  {
    id: 10,
    key: "partners",
    route: "/partners/links/:id",
    component: AuthGuard(ReferalLinks),
  },
  {
    id: 11,
    key: "partners",
    route: "/partners/edit/:id",
    component: AuthGuard(EditPartner),
  },
  {
    id: 12,
    key: "partners",
    route: "/partners/un-verified-users/:id",
    component: AuthGuard(UnverifiedUsers),
  },
  {
    id: 13,
    type: "collapse",
    name: "Active Users",
    key: "active-users",
    route: "/active-users",
    icon: <SiReactivex size="15px" color="inherit" />,
    component: AuthGuard(ActiveUsers),
    noCollapse: true,
  },
  {
    id: 14,
    type: "collapse",
    name: "Active Playing Users",
    key: "active-playing-users",
    route: "/active-playing-users",
    icon: <GiBabyfootPlayers size="15px" color="inherit" />,
    component: AuthGuard(ActivePlayingUsers),
    noCollapse: true,
  },
  { id: 15, type: "title", title: "Artist Pages", key: "Artist-pages" },
  {
    id: 16,
    type: "collapse",
    name: "Affiliate Artist",
    key: "affiliate-artist",
    route: "/affiliate-artist",
    icon: <GiGlowingArtifact size="15px" color="inherit" />,
    component: AuthGuard(AffiliateArtist),
    // noCollapse: true,
  },
  {
    id: 17,
    key: "affiliate-artist",
    route: "/affiliate-artist/add",
    component: AuthGuard(AddAffiliateArtist),
  },
  {
    id: 18,
    type: "collapse",
    name: "Affiliate Requests",
    key: "affiliate-requests",
    route: "/affiliate-requests",
    icon: <GiPaintedPottery size="15px" color="inherit" />,
    component: AuthGuard(AffiliateRequests),
    // noCollapse: true,
  },
  {
    id: 19,
    type: "collapse",
    name: "Products",
    key: "products",
    route: "/products",
    icon: <BiStore size="15px" color="inherit" />,
    component: AuthGuard(Products),
    noCollapse: true,
  },
  {
    id: 20,
    key: "products",
    route: "/products/add",
    component: AuthGuard(AddProductForm),
  },
  {
    id: 21,
    key: "products",
    route: "/products/addsub/:id",
    component: AuthGuard(AddSubProductForm),
  },
  {
    id: 22,
    key: "products",
    route: "/products/detail/:id",
    component: AuthGuard(ProductDetail),
  },
  {
    id: 23,
    key: "products",
    route: "/products/edit/:id",
    component: AuthGuard(EditProductForm),
  },
  {
    id: 24,
    key: "products",
    route: "/products/detail/:id/edit-sub/:subId",
    component: AuthGuard(EditSubProductForm),
  },
  {
    id: 25,
    key: "products",
    route: "/products/addtrack/:id",
    component: AuthGuard(AddTrackForm),
  },

  // {
  //   id: 26,
  //   type: "collapse",
  //   name: "Gift Cards",
  //   key: "gift-cards",
  //   route: "/gift-cards",
  //   icon: <AiFillGift size="15px" color="inherit" />,
  //   component: AuthGuard(GiftCards),
  //   noCollapse: true,
  // },

  // {
  //   id: 27,
  //   key: "gift-cards",
  //   route: "/gift-cards/edit/:id",
  //   component: AuthGuard(EditGiftCard),
  // },

  // {
  //   id: 28,
  //   key: "gift-cards",
  //   route: "/gift-cards/detail/:id",
  //   component: AuthGuard(GiftCardDetail),
  // },

  // {
  //   id: 29,
  //   key: "gift-cards",
  //   route: "/gift-cards/addsub/:id",
  //   component: AuthGuard(AddSubGiftCardForm),
  // },

  { id: 30, type: "title", title: "Games Pages", key: "games-pages" },
  {
    id: 31,
    type: "collapse",
    name: "EvoPlay Games",
    key: "games",
    route: "/games",
    icon: <IoGameControllerSharp size="15px" color="inherit" />,
    component: AuthGuard(GamesPage),
    noCollapse: true,
  },
  {
    id: 32,
    key: "games",
    route: "/games/:id/:gameId/:provider",
    component: AuthGuard(GameDetail),
  },
  // {
  //   id: 33,
  //   type: "collapse",
  //   name: "Riversweeps Games",
  //   key: "riversweepsgames",
  //   route: "/riversweepsgames",
  //   icon: <SiRiotgames size="15px" color="inherit" />,
  //   component: AuthGuard(NetGamesPage),
  //   noCollapse: true,
  // },
  {
    id: 34,
    key: "riversweepsgames",
    route: "/riversweepsgames/:id/:gameId/:provider",
    component: AuthGuard(NetGameDetail),
  },
  {
    id: 35,
    type: "collapse",
    name: "Arrows Edge Games",
    key: "arrowedgegames",
    route: "/arrowedgegames",
    icon: <GiEdgedShield size="15px" color="inherit" />,
    component: AuthGuard(ArrowEdgeGamesListPage),
    noCollapse: true,
  },
  {
    id: 36,
    key: "arrowedgegames",
    route: "/arrowedgegames/:id/:gameId/:provider",
    component: AuthGuard(GameDetail),
  },
  {
    id: 37,
    type: "collapse",
    name: "KA Games",
    key: "kagames",
    route: "/kagames",
    icon: <SiWegame size="15px" color="inherit" />,
    component: AuthGuard(KAGamesListPage),
    noCollapse: true,
  },
  {
    id: 38,
    key: "kagames",
    route: "/kagames/:id/:gameId/:provider",
    component: AuthGuard(GameDetail),
  },
  {
    id: 63,
    type: "collapse",
    name: "LuckyStreak Games",
    key: "luckystreak",
    route: "/luckystreak",
    icon: <GiLuckyFisherman size="15px" color="inherit" />,
    component: AuthGuard(LuckyStreakGamesListPage),
    noCollapse: true,
  },
  {
    id: 64,
    key: "luckystreak",
    route: "/luckystreak/:id/:gameId/:provider",
    component: AuthGuard(GameDetail),
  },
  { id: 39, type: "title", title: "Revenue Pages", key: "revenue-pages" },
  {
    id: 40,
    type: "collapse",
    name: "Profits Report",
    key: "profits",
    route: "/profits",
    icon: <MdAttachMoney size="15px" color="inherit" />,
    component: AuthGuard(Profits),
    noCollapse: true,
  },
  {
    id: 41,
    type: "collapse",
    name: "JK Digitals Transaction",
    key: "transaction-history",
    route: "/transaction-history",
    icon: <FaShoppingCart size="15px" color="inherit" />,
    component: AuthGuard(Transactions),
    noCollapse: true,
  },
  {
    id: 42,
    type: "collapse",
    name: "Cashout History",
    key: "cashout-history",
    route: "/cashout-history",
    icon: <BiMoneyWithdraw size="15px" color="inherit" />,
    component: AuthGuard(CashoutHistory),
    noCollapse: true,
  },
  {
    id: 43,
    type: "collapse",
    name: "Jackpot",
    key: "jackpot",
    route: "/jackpot",
    icon: <FaTrophy size="15px" color="inherit" />,
    component: AuthGuard(Jackpot),
    noCollapse: true,
  },
  {
    id: 44,
    key: "jackpot",
    route: "/jackpot/:id",
    component: AuthGuard(JacpotDetail),
  },
  {
    id: 45,
    type: "collapse",
    name: "States",
    key: "states",
    route: "/states",
    icon: <BiWorld size="15px" color="inherit" />,
    component: AuthGuard(Payments),
    noCollapse: true,
  },
  {
    id: 46,
    type: "collapse",
    name: "Failed Login Report",
    key: "failed-login",
    route: "/failed-login",
    icon: <MdVpnKey size="15px" color="inherit" />,
    component: AuthGuard(FailedLoginReport),
    noCollapse: true,
  },
  {
    id: 47,
    type: "collapse",
    name: "Failed Signup Report",
    key: "failed-signup",
    route: "/failed-signup",
    icon: <FaUserTimes size="15px" color="inherit" />,
    component: AuthGuard(FailedSignUpReport),
    noCollapse: true,
  },
  {
    id: 48,
    type: "collapse",
    name: "Notification",
    key: "notification",
    route: "/notification",
    icon: <FaBell size="15px" color="inherit" />,
    component: AuthGuard(Notifcation),
    noCollapse: true,
  },
  {
    id: 65,
    key: "notification",
    route: "/notification/add",
    component: AuthGuard(AddNewNotification),
  },
  {
    id: 57,
    type: "collapse",
    name: "Promotions",
    key: "promotions",
    route: "/promotions",
    icon: <MdDiscount size="15px" color="inherit" />,
    component: AuthGuard(Promotions),
    noCollapse: true,
  },
  {
    id: 58,
    type: "collapse",
    name: "Coupons",
    key: "coupons",
    route: "/coupons",
    icon: <RiCoupon2Fill size="15px" color="inherit" />,
    component: AuthGuard(Coupons),
    noCollapse: true,
  },
  {
    id: 66,
    type: "collapse",
    name: "Referral Campaign",
    key: "referral-campaigns",
    route: "/referral-campaigns",
    icon: <MdCampaign size="15px" color="inherit" />,
    component: AuthGuard(ReferralCampaign),
    noCollapse: true,
  },
  {
    id: 67,
    key: "referral-campaigns",
    route: "/referral-campaigns/add",
    component: AuthGuard(CreateCampaignPage),
  },
  {
    id: 68,
    key: "referral-campaigns",
    route: "/referral-campaigns/edit/:id",
    component: AuthGuard(EditCampaignPage),
  },
  {
    id: 69,
    key: "referral-campaigns",
    route: "/referral-campaigns/detail/:id",
    component: AuthGuard(DetailCampaignPage),
  },
  {
    id: 62,
    type: "collapse",
    name: "Fortune Wheel",
    key: "fortune-wheel",
    route: "/fortune-wheel",
    icon: <GiCartwheel size="15px" color="inherit" />,
    component: AuthGuard(WheelPrizes),
    noCollapse: true,
  },
  {
    id: 49,
    type: "collapse",
    name: "General Settings",
    key: "general-settings",
    route: "/general-settings",
    icon: <BsFillGearFill size="15px" color="inherit" />,
    component: AuthGuard(GeneralSettings),
    noCollapse: true,
  },
  { id: 50, type: "title", title: "Account Pages", key: "account-pages" },
  {
    id: 51,
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: AuthGuard(Profile),
    noCollapse: true,
  },
  {
    id: 52,
    key: "profile",
    route: "/profile/edit",
    component: AuthGuard(EditProfile),
  },

  {
    id: 53,
    key: "sign-in",
    route: "/authentication/sign-in",
    component: ProtectGuard(SignIn),
  },
  {
    id: 54,
    key: "forgot-password",
    route: "/forgot-password",
    component: ProtectGuard(ForgotPassword),
  },
  {
    id: 55,
    key: "forgot-password-next",
    route: "/forgot-password-next/:mail",
    component: ProtectGuard(ForgotPasswordNext),
  },
  {
    id: 56,
    key: "reset-password",
    route: "/reset-password",
    component: ProtectGuard(ResetPassword),
  },
  {
    id: 59,
    key: "users",
    route: "/users/friends/:id",
    component: AuthGuard(FriendListUser),
  },
  {
    id: 60,
    key: "coupons",
    route: "/coupons/add",
    component: AuthGuard(CreateCoupon),
  },
  {
    id: 61,
    key: "coupons",
    route: "/coupons/edit/:id",
    component: AuthGuard(EditCoupon),
  },
];

export default routes;

// Last route is 69
