import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Toast from "components/Toastify/Toastify";
import PartnerServices from "store/api/partnersServices";
import storage from "./../../services/storage";
import SocketService from "services/socket.service";

const initialState = {
  status: "idle",
  data: [],
  totalPages: 1,
  totalCount: 1,
};
export const retrivePartners = createAsyncThunk("partners/get", async ({ data, history }) => {
  const response = await PartnerServices.get(data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  }

  return response.data;
});

export const addPartner = createAsyncThunk("partner/add", async ({ data, history }) => {
  const res = await PartnerServices.add(data);

  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/partners");
  } else {
    if (res.data.success == false) {
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (
      (res.data.success == false &&
        response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      response.data.message === "Sorry! Services not available in your country." ||
      response.data.message === "Sorry! Services not available in your region." ||
      response.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  return res.data.user;
});

const partnerSlice = createSlice({
  name: "partners",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addPartner.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(addPartner.fulfilled, (state, action) => {
        state.status = "fulfilled";
      }),
      builder.addCase(retrivePartners.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(retrivePartners.fulfilled, (state, action) => {
        state.data = action.payload?.data?.partners;
        state.totalCount = action.payload?.data?.totalCount;
        state.totalPages = action.payload?.data?.totalPages;
        state.status = "fulfilled";
      });
  },
});

export default partnerSlice.reducer;
