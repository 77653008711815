import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";
import SettingsServices from "store/api/generalSettings";

// const DateConverter = (d) => {
//   const date = new Date(d).toLocaleDateString();
//   return date;
// };

const initialState = {
  data: {},
  settingsStatus: "idle",
};

export const retriveSettings = createAsyncThunk("settings/retrieve", async ({ history }) => {
  const res = await SettingsServices.getSettings();
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});
export const updateSettings = createAsyncThunk("state/update", async ({ data, history }) => {
  const res = await SettingsServices.updateSettings(data);

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(retriveSettings.pending, (state, action) => {
      state.settingsStatus = "pending";
    }),
      builder.addCase(retriveSettings.fulfilled, (state, action) => {
        state.data = action.payload?.data || {};
        state.settingsStatus = "fullfilled";
      }),
      builder.addCase(retriveSettings.rejected, (state, action) => {
        state.settingsStatus = "rejected";
        state.data = {};
      }),
      builder.addCase(updateSettings.pending, (state, action) => {
        state.settingsStatus = "pending";
      }),
      builder.addCase(updateSettings.fulfilled, (state, action) => {
        state.data = action.payload?.data;
        state.settingsStatus = "fullfilled";
      }),
      builder.addCase(updateSettings.rejected, (state, action) => {
        state.settingsStatus = "rejected";
        state.data = {};
      });
  },
});

// const { } = userSlice.actions;
export default settingsSlice.reducer;
