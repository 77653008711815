import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SingleUserService from "./../api/singleUser";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";
import { FaLessThanEqual } from "react-icons/fa";

const initialState = {
  data: {},
  jumio: {},
  notes: [],
  topGames: [],
  winLossRatio: {},
  bets: {},
  verifiedUsers: {},
  totalTime: 0,
  timeText: "",
  cashout: 0,
  spins: {
    data: [],
    totalCount: 0,
    totalPages: 1,
  },
  status: "idle",
  noteStatus: "idle",
  cashoutStatus: "idle",
  betsStatus: "idle",
  spinsUserStatus: "idle",
};

export const retriveSingleUser = createAsyncThunk("user/retrieve", async ({ id, history }) => {
  const res = await SingleUserService.getSingleUser(id);
  res.data.myStatus = "a";

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    res.data.myStatus = "error";
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const enableUser = createAsyncThunk("user/enable/disable", async ({ data, history }) => {
  const res = await SingleUserService.enable(data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  let responsData = {data: res.data, status: data.status}
  return responsData;
});

export const editUser = createAsyncThunk("user/edit", async ({ id, data, history }) => {
  const res = await SingleUserService.edit(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
    }
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});

export const flagUser = createAsyncThunk("user/flag", async ({ data, history }) => {
  const res = await SingleUserService.flag(data);
  if (res.data.success === true) {
    Toast.success(
      data.isFlag === true
        ? "User Flagged Activated Successfully"
        : "User Flagged De-Activated Successfully",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return data;
});

export const retrieveNotes = createAsyncThunk("user/retriveNote", async ({ id, history }) => {
  const res = await SingleUserService.getNote(id);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const addNote = createAsyncThunk("user/addNote", async ({ data, history }) => {
  const res = await SingleUserService.addNote(data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const deleteNote = createAsyncThunk("user/deleteNote", async ({ id, history }) => {
  const res = await SingleUserService.deleteNote(id);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return id;
});

export const cashout = createAsyncThunk("user/cashout", async ({ id, data, history }) => {
  const res = await SingleUserService.cashout(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const betsOverView = createAsyncThunk("user/bets", async ({ userId, data, history }) => {
  const res = await SingleUserService.betsOverView(userId, data);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const jumioAttempt = createAsyncThunk("user/jumioAttempt", async ({ id, data, history }) => {
  const res = await SingleUserService.jumioAttempt(id, data);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});


export const manualTransaction = createAsyncThunk("user/manualTransaction", async ({ id, data, history }) => {
  const sendData = data
  const res = await SingleUserService.manualTransaction(id, data);

  if (res.data.success === true) {
    Toast.success(`${sendData.coins === null ? `$ ${sendData.amount}` : `${sendData.coins} coins` } added to wallet successfully`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  return res.data
  }else if(res.data.success === false){
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  // return res.data;
});

export const manualCashout = createAsyncThunk("user/manualCashout", async ({ id, data, history }) => {
  const sendData = data
  const res = await SingleUserService.manualCashout(id, data);

  if (res.data.success === true) {
    Toast.success(`$ ${sendData.amount} Cashed out successfully`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  return res.data
  }else if(res.data.success === false){
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  // return res.data;
});

export const verifiedUsers = createAsyncThunk("user/verifiedUsers", async ({ history }) => {

  const res = await SingleUserService.verifiedUsers();

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const retriveSpins = createAsyncThunk("user/spins", async ({ id, data, history }) => {
  const { page, perPage, search, orderBy, sortBy, startDate, endDate } = data
  const res = await SingleUserService.getSpins({id, page, perPage, startDate, endDate });
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    idleStateUser: (state) => {
      state.status = "idle";
    }
  },
  extraReducers: (builder) => {
    builder.addCase(retriveSingleUser.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(retriveSingleUser.fulfilled, (state, action) => {
        state.data = action.payload?.user;
        state.jumio = action.payload?.jumio;
        state.status = action.payload.myStatus === "error" ? "rejected" : "fullfilled";
      }),
      builder.addCase(retriveSingleUser.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(enableUser.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(enableUser.fulfilled, (state, action) => {
        let {data, status} = action.payload
        if(data.success){
          if(data.note){
            state.notes.push(data.note);
          }
          state.data.status = status;
        }
        state.status = "fulfilled";
      }),
      builder.addCase(enableUser.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(editUser.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(editUser.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.data = action.payload.user;
        }
        state.status = "fullfilled";
      }),
      builder.addCase(editUser.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(retrieveNotes.pending, (state, action) => {
        state.noteStatus = "pending";
      }),
      builder.addCase(retrieveNotes.fulfilled, (state, action) => {
        state.notes = action.payload?.Data;
        state.noteStatus = "fullfilled";
      }),
      builder.addCase(retrieveNotes.rejected, (state, action) => {
        state.noteStatus = "rejected";
      }),
      builder.addCase(addNote.pending, (state, action) => {
        state.noteStatus = "pending";
      }),
      builder.addCase(addNote.fulfilled, (state, action) => {
        state.notes.push(action.payload?.Data);
        // state.notes = action.payload?.Data;
        state.noteStatus = "fullfilled";
      }),
      builder.addCase(addNote.rejected, (state, action) => {
        state.noteStatus = "rejected";
      }),
      builder.addCase(flagUser.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(flagUser.fulfilled, (state, action) => {
        state.data.isFlag = action.payload.isFlag;
        state.status = "fullfilled";
      }),
      builder.addCase(flagUser.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(deleteNote.pending, (state, action) => {
        state.noteStatus = "pending";
      }),
      builder.addCase(deleteNote.fulfilled, (state, action) => {
        const index = state.notes.findIndex((item) => item._id === action.payload);
        state.notes.splice(index, 1);
        state.noteStatus = "fullfilled";
      }),
      builder.addCase(deleteNote.rejected, (state, action) => {
        state.noteStatus = "rejected";
      }),
      builder.addCase(cashout.pending, (state, action) => {
        state.cashoutStatus = "pending";
      }),
      builder.addCase(cashout.fulfilled, (state, action) => {
        let temp = {
          win: 0,
          total: 0,
        };
        state.winLossRatio = temp;
        state.cashout = action.payload.totalCashedOut || 0;
        state.topGames = action.payload.topGames || [];
        state.winLossRatio = action.payload.winLossRatio || temp;
        state.totalTime = action.payload.totalPlayedTime || 0;
        state.timeText = action.payload.totalPlayedText;
        state.cashoutStatus = "fullfilled";
      }),
      builder.addCase(cashout.rejected, (state, action) => {
        state.cashoutStatus = "rejected";
      }),
      builder.addCase(betsOverView.pending, (state, action) => {
        state.betsStatus = "pending";
      }),
      builder.addCase(betsOverView.fulfilled, (state, action) => {
        state.bets = action.payload || {};
        state.betsStatus = "fullfilled";
      }),
      builder.addCase(betsOverView.rejected, (state, action) => {
        state.betsStatus = "rejected";
      }),
      builder.addCase(jumioAttempt.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(jumioAttempt.fulfilled, (state, action) => {
        state.jumio = action.payload?.jumio;
        state.status = action.payload.myStatus === "error" ? "rejected" : "fullfilled";
      }),
      builder.addCase(jumioAttempt.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(manualTransaction.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(manualTransaction.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.data = action.payload?.data?.user;
        }
        state.status = "fullfilled";
      }),
      builder.addCase(manualTransaction.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(manualCashout.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(manualCashout.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.data = action.payload?.user;
        }
        state.status = "fullfilled";
      }),
      builder.addCase(manualCashout.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(verifiedUsers.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(verifiedUsers.fulfilled, (state, action) => {
        state.verifiedUsers = action.payload?.data
        state.status = "fullfilled";
      }),
      builder.addCase(verifiedUsers.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(retriveSpins.pending, (state, action) => {
        state.spinsUserStatus = "pending";
      }),
      builder.addCase(retriveSpins.fulfilled, (state, action) => {
        if(action.payload.success === true){
          state.spins.data = action.payload?.data
          state.spins.totalCount = action.payload?.totalCount
          state.spins.totalPages = action.payload?.totalPages
        }
       
        state.spinsUserStatus = "fullfilled";
      }),
      builder.addCase(retriveSpins.rejected, (state, action) => {
        state.spinsUserStatus = "rejected";
      })
  },
});

export const {idleStateUser } = userSlice.actions;
export default userSlice.reducer;
