import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";
import CouponsServices from "store/api/couponsServices";

const initialState = {
  currentPage: 1,
  orderBy: "desc",
  perPage: 10,
  sortBy: "createdAt",
  searchBy: "",
  totalPages: 0,
  totalCount: 0,
  couponsList: [],
  couponsStatus: "idle",
  couponCardStatus: "idle",
};

export const retriveCoupons = createAsyncThunk("allCoupons/retrieve", async ({ data, history }) => {
  const { page, perPage, search } = data;
  const res = await CouponsServices.getAll({ page, perPage, search });
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});
export const createCoupon = createAsyncThunk("coupon/create", async ({ data, history }) => {
  const res = await CouponsServices.create(data);

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else if (res.data.success === false) {
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else if (res.data.success === true) {
    Toast.success("Coupon Created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/coupons");
    return res.data;
  }
});

export const disableCoupon = createAsyncThunk("disableCoupon", async ({ id, history }) => {
  const response = await CouponsServices.disable(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});
export const enableCoupon = createAsyncThunk("enableCoupon", async ({ id, history }) => {
  const response = await CouponsServices.enable(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

export const expireCoupon = createAsyncThunk("expireCoupon", async ({ id, history }) => {
  const response = await CouponsServices.expire(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});


export const editCoupon = createAsyncThunk("editCoupon", async ({ id, data, history }) => {
  const response = await CouponsServices.edit(id, data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/coupons");
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

const couponsSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retriveCoupons.pending, (state, action) => {
      state.couponsStatus = "pending";
    }),
      builder.addCase(retriveCoupons.fulfilled, (state, action) => {
        state.couponsList = action.payload?.coupons || [];
        state.totalPages = action.payload?.totalPages || 1;
        state.totalCount = action.payload?.totalCount || 1;
        state.couponsStatus = "fullfilled";
      }),
      builder.addCase(retriveCoupons.rejected, (state, action) => {
        state.couponsStatus = "rejected";
        state.couponsList = [];
      }),
      builder.addCase(createCoupon.pending, (state, action) => {
        state.couponsStatus = "pending";
      }),
      builder.addCase(createCoupon.fulfilled, (state, action) => {
        state.couponsStatus = "fullfilled";
      }),
      builder.addCase(createCoupon.rejected, (state, action) => {
        state.couponsStatus = "rejected";
        state.couponsList = [];
      }),
      builder.addCase(disableCoupon.pending, (state, action) => {
        state.couponCardStatus = "pending";
      }),
      builder.addCase(disableCoupon.fulfilled, (state, action) => {
        const index = state.couponsList.findIndex((item) => item?._id === action.payload?.coupon?._id);
        state.couponsList[index] = action.payload.coupon
        state.couponCardStatus = "fullfilled";
      }),
      builder.addCase(disableCoupon.rejected, (state, action) => {
        state.couponCardStatus = "rejected";
      }),
      builder.addCase(enableCoupon.pending, (state, action) => {
        state.couponCardStatus = "pending";
      }),
      builder.addCase(enableCoupon.fulfilled, (state, action) => {
        const index = state.couponsList.findIndex((item) => item?._id === action.payload?.coupon?._id);

        state.couponsList[index] = action.payload.coupon
   
        state.couponCardStatus = "fullfilled";
      }),
      builder.addCase(enableCoupon.rejected, (state, action) => {
        state.couponCardStatus = "rejected";
      }),
      builder.addCase(expireCoupon.pending, (state, action) => {
        state.couponCardStatus = "pending";
      }),
      builder.addCase(expireCoupon.fulfilled, (state, action) => {
        const index = state.couponsList.findIndex((item) => item?._id === action.payload?.coupon?._id);
        state.couponsList[index] = action.payload.coupon
        state.couponCardStatus = "fullfilled";
      }),
      builder.addCase(expireCoupon.rejected, (state, action) => {
        state.couponCardStatus = "rejected";
      }),
      builder.addCase(editCoupon.pending, (state, action) => {
        state.couponCardStatus = "pending";
      }),
      builder.addCase(editCoupon.fulfilled, (state, action) => {
        const index = state.couponsList.findIndex((item) => item?._id === action.payload?.coupon?._id);
        state.couponsList[index] = action.payload?.coupon
        state.couponCardStatus = "fullfilled";
      }),
      builder.addCase(editCoupon.rejected, (state, action) => {
        state.couponCardStatus = "rejected";
      });
  },
});

export const { setCurrentPage, setOrderBy, setPerPage, setSortBy, setSearchBy } =
  couponsSlice.actions;
export default couponsSlice.reducer;
