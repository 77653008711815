import storage from "services/storage";
import http, { httpForm } from "./http-common";

const getProducts = () => {
  return http.get(`/product/getmainproduct`, 
  {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const getSubProducts = (id) => {
  return http.get(`/product/getall/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const addProduct = (data) => {
  return http.post("/product", data, {
    headers: {
      "Content-type": "multipart/form-data",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const addProductTrack = (data, id) => {
  return http.put(`/product/addtrack/${id}`, data, {
    headers: {
      "Content-type": "multipart/form-data",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const delProductTrack = (data, id) => {
  return http.put(`/product/softdeletetrack/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  }
  );
};




const addSubProduct = (id, data) => {
  return http.post(`/product/add/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const update = (id, data) => {
  return http.put(`/product/${id}`, data, {
    headers: {
      "Content-type": "multipart/formdata",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const updateSub = (id, data) => {
  return http.put(`/product/updatesubproduct/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const remove = ({ id }) => {
  return http.delete(`/product/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const removeSub = (id) => {
  return http.delete(`/product/delete/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
//   const removeAll = () => {
//     return http.delete(`/tutorials`);
//   };

//   const findByTitle = title => {
//     return http.get(`/tutorials?title=${title}`);
//   };

const ProductServices = {
  getProducts,
  addProduct,
  remove,
  update,
  getSubProducts,
  addSubProduct,
  updateSub,
  removeSub,
  addProductTrack,
  delProductTrack
};

export default ProductServices;
