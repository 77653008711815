export function formatNumber(number) {
  if (Number.isInteger(number)) {
    return number?.toString();
  } else {
    return number?.toFixed(2);
  }
}
export const TimestampComponent = ({ isoTimestamp }) => {
  const date = new Date(isoTimestamp);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(date);

  return (
    <div>
      <p> {formattedDateTime}</p>
    </div>
  );
};