import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";
import NotificationServices from "store/api/notificationServices";

const initialState = {
  currentPage: 1,
  orderBy: "desc",
  perPage: 10,
  sortBy: 'createdAt',
  searchBy: '',
  totalPages: 0,
  totalCount: 0,
  notificactions: [],
  notificationStatus: "idle",
};

export const retriveNotifications = createAsyncThunk("allNotifications/retrieve", async ({ data, history }) => {
  const res = await NotificationServices.getAll(data);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});
export const createNotification = createAsyncThunk("notification/create", async ({  data, history }) => {
  const res = await NotificationServices.create(data);

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else if (res.data.success === false) {
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/notification");
    return res.data;
  }
});

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retriveNotifications.pending, (state, action) => {
      state.notificationStatus = "pending";
    }),
      builder.addCase(retriveNotifications.fulfilled, (state, action) => {
        state.notificactions = action.payload?.data || [];
        state.totalPages = action.payload?.totalPages || 1;
        state.totalCount = action.payload?.totalCount || 1;
        state.notificationStatus = "fullfilled";
      }),
      builder.addCase(retriveNotifications.rejected, (state, action) => {
        state.notificationStatus = "rejected";
        state.notificactions = [];
      }),
      builder.addCase(createNotification.pending, (state, action) => {
        state.notificationStatus = "pending";
      }),
      builder.addCase(createNotification.fulfilled, (state, action) => {
        state.notificactions.push(action.payload?.data);
        state.notificationStatus = "fullfilled";
      }),
      builder.addCase(createNotification.rejected, (state, action) => {
        state.notificationStatus = "rejected";
        // state.states = [];
      });
  },
});

export const { setCurrentPage, setOrderBy, setPerPage, setSortBy, setSearchBy } = notificationSlice.actions;
export default notificationSlice.reducer;
