import axios from "axios";
import { baseUrl } from "config";
import storage from "services/storage";

export default axios.create({
  baseURL: baseUrl,
});

// Create an Axios instance with default configuration
// const http = axios.create({
//   baseURL: 'https://api.example.com',
// });

// // Request interceptor: Add authorization token to each request
// http.interceptors.request.use(
//   (config) => {
//     // Here you can modify the request config, like adding headers
//     // For example, you can add an authorization token
//     const token = localStorage.getItem('accessToken');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     // Handle request error
//     return Promise.reject(error);
//   }
// );

// // Response interceptor: Handle global error responses
// http.interceptors.response.use(
//   (response) => {
//     // Here you can handle successful responses globally
//     return response;
//   },
//   (error) => {
//     // Here you can handle errors globally
//     // For example, you can redirect to an error page or display a notification
//     return Promise.reject(error);
//   }
// );

// export default http;


export const httpForm = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "multipart/form-data",
    "x-auth-token": `${storage.get("xAuthToken")}`,
  },
});
