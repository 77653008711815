import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Toast from "components/Toastify/Toastify";
import GiftCardServices from "store/api/giftCards";
import storage from "./../../services/storage";
import SocketService from "services/socket.service";

const initialState = {
  status: "idle",
  data: [],
  sub: [],
};

export const addGiftCard = createAsyncThunk("giftCard/add", async ({ data, history }) => {
  const res = await GiftCardServices.addGiftCard(data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/gift-cards");
  } else {
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  return res.data.data;
});

export const addSubGiftCard = createAsyncThunk(
  "giftCard/sub-add",
  async ({ id, data, history }) => {
    const res = await GiftCardServices.addSubGiftCard(id, data);
    if (res.data.success === true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      history.push(`/gift-cards/detail/${id}`);
    } else {
      if (
        (res.data.success == false &&
          res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
        res.data.message === "Sorry! Services not available in your country." ||
        res.data.message === "Sorry! Services not available in your region." ||
        res.data.message === "You cannot use LionsDen with a VPN or Proxy."
      ) {
        storage.remove("xAuthToken");
        storage.remove("user");
        SocketService.disconnect();
        history.push("/authentication/sign-in");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    return res.data.data;
  }
);

export const updateGiftCard = createAsyncThunk("giftCard/update", async ({ id, data, history }) => {
  const res = await GiftCardServices.update(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/gift-cards");
  } else {
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  return res.data.data;
});

export const updateSubGiftCard = createAsyncThunk(
  "giftCard/sub-update",
  async ({ subId, data, history, id }) => {
    const res = await GiftCardServices.updateSub(subId, data);
    if (res.data.success === true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      history.push(`/gift-cards/detail/${id}`);
    } else {
      if (
        (res.data.success == false &&
          res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
        res.data.message === "Sorry! Services not available in your country." ||
        res.data.message === "Sorry! Services not available in your region." ||
        res.data.message === "You cannot use LionsDen with a VPN or Proxy."
      ) {
        storage.remove("xAuthToken");
        storage.remove("user");
        SocketService.disconnect();
        history.push("/authentication/sign-in");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    return res.data.data;
  }
);

export const retriveGiftCards = createAsyncThunk("giftCards/get-all", async ({ history }) => {
  try {
    const res = await GiftCardServices.getGiftCards();
    return res.data.data;
  } catch (error) {
    if (
      error.response.data.message === "Your Session has been expired, Kindly sign in to continue."
    ) {
      Toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
    }
  }
});
export const retriveSubGiftCards = createAsyncThunk(
  "giftCards/sub-get",
  async ({ history, id }) => {
    const res = await GiftCardServices.getSubGiftCards(id);
    if (
      (res.data.success === false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
    } else {
      if (
        (res.data.success == false &&
          res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
        res.data.message === "Sorry! Services not available in your country." ||
        res.data.message === "Sorry! Services not available in your region." ||
        res.data.message === "You cannot use LionsDen with a VPN or Proxy."
      ) {
        storage.remove("xAuthToken");
        storage.remove("user");
        SocketService.disconnect();
        history.push("/authentication/sign-in");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    return res.data.data;
  }
);
export const deleteGiftCard = createAsyncThunk("giftCards/delete", async ({ id, history }) => {
  const res = await GiftCardServices.remove({ id });
  if (res.data.success == true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
  return { id };
});

export const deleteSubGiftCard = createAsyncThunk(
  "giftCards/subdelete",
  async ({ _id, history }) => {
    const res = await GiftCardServices.removeSub(_id);
    if (res.data.success == true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      if (
        (res.data.success == false &&
          res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
        res.data.message === "Sorry! Services not available in your country." ||
        res.data.message === "Sorry! Services not available in your region." ||
        res.data.message === "You cannot use LionsDen with a VPN or Proxy."
      ) {
        storage.remove("xAuthToken");
        storage.remove("user");
        SocketService.disconnect();
        history.push("/authentication/sign-in");
        Toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    return { _id };
  }
);

const giftCardSlice = createSlice({
  name: "giftCards",
  initialState,
  reducers: {
    removeProduct: (state, action) => {
      const index = state.data.data.findIndex((item) => item._id === action.payload);
      state.data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addGiftCard.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(addGiftCard.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(addSubGiftCard.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(addSubGiftCard.fulfilled, (state, action) => {
        state.sub.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(updateGiftCard.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(updateGiftCard.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item?._id === action.payload?._id);
        state.data[index] = {
          ...state.data[index],
          ...action.payload,
        };
        // state.data.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(updateSubGiftCard.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(updateSubGiftCard.fulfilled, (state, action) => {
        const index = state.sub.findIndex((item) => item?._id === action.payload?._id);
        state.sub[index] = {
          ...state.sub[index],
          ...action.payload,
        };
        // state.data.push(action.payload);
        state.status = "fulfilled";
      }),
      builder.addCase(retriveGiftCards.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(retriveGiftCards.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "fulfilled";
      }),
      builder.addCase(retriveSubGiftCards.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(retriveSubGiftCards.fulfilled, (state, action) => {
        state.sub = action.payload;
        state.status = "fulfilled";
      }),
      builder.addCase(deleteGiftCard.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(deleteGiftCard.fulfilled, (state, action) => {
        const index = state.data.findIndex((item) => item._id === action.payload.id);
        state.data.splice(index, 1);
        state.status = "fulfilled";
      }),
      builder.addCase(deleteSubGiftCard.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(deleteSubGiftCard.fulfilled, (state, action) => {
        const index = state.sub.findIndex((item) => item._id === action.payload._id);
        state.sub.splice(index, 1);
        state.status = "fulfilled";
      });
  },
});

export const { removeProduct } = giftCardSlice.actions;
export default giftCardSlice.reducer;
