import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";
import CampaignServices from "store/api/campaignReferralServices";

const initialState = {
  currentPage: 1,
  orderBy: "desc",
  perPage: 10,
  sortBy: "createdAt",
  searchBy: "",
  totalPages: 0,
  totalCount: 0,
  campaignsList: [],
  campaignStatus: "idle",
  campaignCardStatus: "idle",
};

export const retriveCampaigns = createAsyncThunk("allCampaigns/retrieve", async ({ data, history }) => {
  const { page, perPage} = data;
  const res = await CampaignServices.getAll({ page, perPage});
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  return res.data;
});
export const createCampaign = createAsyncThunk("campaign/create", async ({ data, history }) => {
  const res = await CampaignServices.create(data);

  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else if (res.data.success === false) {
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else if (res.data.success === true) {
    Toast.success("Campaign Created Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/referral-campaigns");
    return res.data;
  }
});

export const disableCampaign = createAsyncThunk("disableCampaign", async ({ id, history }) => {
  const response = await CampaignServices.disable(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});
export const enableCampaign = createAsyncThunk("enableCampaign", async ({ id, history }) => {
  const response = await CampaignServices.enable(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

export const expireCampaign = createAsyncThunk("expireCampaign", async ({ id, history }) => {
  const response = await CampaignServices.expire(id);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});


export const editCampaign = createAsyncThunk("editCampaign", async ({ id, data, history }) => {
  const response = await CampaignServices.edit(id, data);
  if (
    (response.data.success === false &&
      response.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    response.data.message === "Sorry! Services not available in your country." ||
    response.data.message === "Sorry! Services not available in your region." ||
    response.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/signin");
  } else if (response.data.success !== false) {
    Toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    history.push("/referral-campaigns");
    return response.data;
  } else {
    Toast.error(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  // return response.data;
});

const campaignSlice = createSlice({
  name: "campaignsReferral",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSearchBy: (state, action) => {
      state.searchBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retriveCampaigns.pending, (state, action) => {
      state.campaignStatus = "pending";
    }),
      builder.addCase(retriveCampaigns.fulfilled, (state, action) => {
        state.campaignsList = action.payload?.campaigns || [];
        state.totalPages = action.payload?.totalPages || 1;
        state.totalCount = action.payload?.totalCount || 1;
        state.campaignStatus = "fullfilled";
      }),
      builder.addCase(retriveCampaigns.rejected, (state, action) => {
        state.campaignStatus = "rejected";
        state.campaignsList = [];
      }),
      builder.addCase(createCampaign.pending, (state, action) => {
        state.campaignStatus = "pending";
      }),
      builder.addCase(createCampaign.fulfilled, (state, action) => {
        state.campaignStatus = "fullfilled";
      }),
      builder.addCase(createCampaign.rejected, (state, action) => {
        state.campaignStatus = "rejected";
        state.campaignsList = [];
      }),
      builder.addCase(disableCampaign.pending, (state, action) => {
        state.campaignCardStatus = "pending";
      }),
      builder.addCase(disableCampaign.fulfilled, (state, action) => {
        const index = state.campaignsList.findIndex((item) => item?._id === action.payload?.campaign?._id);
        state.campaignsList[index] = action.payload.campaign
        state.campaignCardStatus = "fullfilled";
      }),
      builder.addCase(disableCampaign.rejected, (state, action) => {
        state.campaignCardStatus = "rejected";
      }),
      builder.addCase(enableCampaign.pending, (state, action) => {
        state.campaignCardStatus = "pending";
      }),
      builder.addCase(enableCampaign.fulfilled, (state, action) => {
        const index = state.campaignsList.findIndex((item) => item?._id === action.payload?.campaign?._id);

        
        state.campaignsList[index] = action.payload.campaign
   
        state.campaignCardStatus = "fullfilled";
      }),
      builder.addCase(enableCampaign.rejected, (state, action) => {
        state.campaignCardStatus = "rejected";
      }),
      builder.addCase(expireCampaign.pending, (state, action) => {
        state.campaignCardStatus = "pending";
      }),
      builder.addCase(expireCampaign.fulfilled, (state, action) => {
        const index = state.campaignsList.findIndex((item) => item?._id === action.payload?.campaign?._id);
        state.campaignsList[index] = action.payload.campaign
        state.campaignCardStatus = "fullfilled";
      }),
      builder.addCase(expireCampaign.rejected, (state, action) => {
        state.campaignCardStatus = "rejected";
      }),
      builder.addCase(editCampaign.pending, (state, action) => {
        state.campaignCardStatus = "pending";
      }),
      builder.addCase(editCampaign.fulfilled, (state, action) => {
        // const index = state.campaignsList.findIndex((item) => item?._id === action.payload?.campaign?._id);
        // state.campaignsList[index] = action.payload?.campaign
        state.campaignCardStatus = "fullfilled";
      }),
      builder.addCase(editCampaign.rejected, (state, action) => {
        state.campaignCardStatus = "rejected";
      });
  },
});

export const { setCurrentPage, setOrderBy, setPerPage, setSortBy, setSearchBy } =
  campaignSlice.actions;
export default campaignSlice.reducer;
