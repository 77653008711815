import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { VisionUIControllerProvider } from "context";
import { ToastContainer } from "react-toastify";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <VisionUIControllerProvider>
      <ToastContainer position="top-right" theme="dark" />
      <App />
    </VisionUIControllerProvider>
  </BrowserRouter>
);
