import storage from "services/storage";
import http, { httpForm } from "./http-common";

const getGiftCards = () => {
  return http.get(`/gift-card`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const getSubGiftCards = (id) => {
  return http.get(`/gift-card/sub/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const addGiftCard = (data) => {
  return http.post("/gift-card", data, {
    headers: {
      "Content-type": "multipart/form-data",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const addSubGiftCard = (id, data) => {
  return http.post(`/gift-card/sub/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const update = (id, data) => {
  return http.put(`/gift-card/${id}`, data, {
    headers: {
      "Content-type": "multipart/formdata",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const updateSub = (id, data) => {
  return http.put(`/gift-card/sub/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const remove = ({ id }) => {
  return http.delete(`/gift-card/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
const removeSub = (id) => {
  return http.delete(`/gift-card/sub/${id}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};
//   const removeAll = () => {
//     return http.delete(`/tutorials`);
//   };

//   const findByTitle = title => {
//     return http.get(`/tutorials?title=${title}`);
//   };

const GiftCardServices = {
  getGiftCards,
  addGiftCard,
  remove,
  update,
  getSubGiftCards,
  addSubGiftCard,
  updateSub,
  removeSub,
};

export default GiftCardServices;
