// export const baseUri = "http://192.168.0.176:8000";
// export const baseUrl = "https://www.lionsprideengineering.com/api/v1/admin/";

// export const apiPath = baseUrl;
// production server Elastic Beanstalk link
// export const baseUri = "http://lionsdeneng-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com";

// Staging server backend
// export const baseUri = "http://lionsdengames-staging-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com";

// Production server backend
export const baseUri = "https://www.lionsprideengineering.com";

export const baseUrl = `${baseUri}/api/v1/admin/`;
export const apiPath = baseUrl;
