import React, { Suspense, lazy } from "react";
import AuthGuard from "guards/authGuard";
import ProtectGuard from "guards/protectGuard";
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
const SignIn = lazy(() => lazyRetry(() => import("layouts/authentication/sign-in")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("layouts/authentication/forgot-password"))
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./layouts/authentication/reset-password"))
);
const Users = lazy(() => lazyRetry(() => import("layouts/users")));
const User = lazy(() => lazyRetry(() => import("layouts/user")));
const ForgotPasswordNext = lazy(() =>
  lazyRetry(() => import("layouts/authentication/forgot-password-next"))
);
const  FailedLoginReport= lazy(() => lazyRetry(() => import("layouts/failedLogin")));
const Transactions = lazy(() => lazyRetry(() => import("layouts/TransactionHistory")));
const CashoutHistory = lazy(() => lazyRetry(() => import("layouts/CashoutHistory")));
const UserTransactionDetails = lazy(() => lazyRetry(() => import("layouts/user/UserTransactionDetails")));
const  FailedSignUpReport= lazy(() => lazyRetry(() => import("layouts/failedSignup")));
const Promotions = lazy(() => lazyRetry(() => import("layouts/Promotions")));
// icons
import { FaUsers, FaUserTimes } from "react-icons/fa";
import { MdDiscount, MdVpnKey } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
const FriendListUser = lazy(() => lazyRetry(() => import("layouts/user/FriendListUser")));
const Payments = lazy(() => lazyRetry(() => import("layouts/payments")));



const routesSupport = [
  {
    id: 2,
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <FaUsers size="15px" color="inherit" />,
    component: AuthGuard(Users),
    noCollapse: true,
  },
  {
    id: 12,
    type: "collapse",
    name: "Failed Login Report",
    key: "failed-login",
    route: "/failed-login",
    icon: <MdVpnKey size="15px" color="inherit" />,
    component: AuthGuard(FailedLoginReport),
    noCollapse: true,
  },
  {
    id: 50,
    type: "collapse",
    name: "Failed Signup Report",
    key: "failed-signup",
    route: "/failed-signup",
    icon: <FaUserTimes size="15px" color="inherit" />,
    component: AuthGuard(FailedSignUpReport),
    noCollapse: true,
  },
  
  {
    id: 39,
    type: "collapse",
    name: "JK Digitals Transaction",
    key: "transaction-history",
    route: "/transaction-history",
    icon: <MdAttachMoney size="15px" color="inherit" />,
    component: AuthGuard(Transactions),
    noCollapse: true,
  },
  {
    id: 40,
    type: "collapse",
    name: "Cashout History",
    key: "cashout-history",
    route: "/cashout-history",
    icon: <MdAttachMoney size="15px" color="inherit" />,
    component: AuthGuard(CashoutHistory),
    noCollapse: true,
  },
  {
    id: 57,
    type: "collapse",
    name: "Promotions",
    key: "promotions",
    route: "/promotions",
    icon: <MdDiscount size="15px" color="inherit" />,
    component: AuthGuard(Promotions),
    noCollapse: true,
  },
    {
      id: 45,
      type: "collapse",
      name: "States",
      key: "states",
      route: "/states",
      icon: <BiWorld size="15px" color="inherit" />,
      component: AuthGuard(Payments),
      noCollapse: true,
    },
  // { id: 13, type: "title", title: "Account Pages", key: "account-pages" },
  // {
  //   id: 14,
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   icon: <BsFillPersonFill size="15px" color="inherit" />,
  //   component: AuthGuard(Profile),
  //   noCollapse: true,
  // },
  {
    id: 15,
    key: "sign-in",
    route: "/authentication/sign-in",
    component: ProtectGuard(SignIn),
  },
  {
    id: 16,
    key: "forgot-password",
    route: "/forgot-password",
    component: ProtectGuard(ForgotPassword),
  },
  {
    id: 17,
    key: "forgot-password-next",
    route: "/forgot-password-next/:mail",
    component: ProtectGuard(ForgotPasswordNext),
  },
  {
    id: 18,
    key: "reset-password",
    route: "/reset-password",
    component: ProtectGuard(ResetPassword),
  },
  {
    id: 20,
    key: "users",
    route: "/users/:id",
    component: AuthGuard(User),
  },
  {
    id: 31,
    key: "users",
    route: "/users/ledger/:id",
    component: AuthGuard(UserTransactionDetails),
  },
  {
    id: 57,
    key: "users",
    route: "/users/friends/:id",
    component: AuthGuard(FriendListUser),
  }
];

export default routesSupport;
