import storage from "services/storage";
import http from "./http-common";


const getAll = (data) => {
  return http.post(`/notifications`, data, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const create = (data) => {
  return http.post(
    `/notifications/send`,
    data,
    {
      headers: {
        "Content-type": "multipart/form-data",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const NotificationServices = {
  getAll,
  create,
}
export default NotificationServices;
