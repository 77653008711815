import storage from "services/storage";
import http from "./http-common";

const getNewUser = (data) => {
  return http.post(
    `/analytics/new_users`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getActiveUser = () => {
  return http.get(`/analytics/active_users_now`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getActiveUsers = (data) => {
  return http.post(
    `/analytics/active_users`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getTopGames = (data, limit) => {
  return http.post(
    `/analytics/top_games?limit=${limit}`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getBets = (data) => {
  return http.post(
    `/games/graph/bets`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getCashout = (data) => {
  return http.post(
    `/analytics/graph/cashouts`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getProfit = (data) => {
  return http.post(
    `partner/allUserProfit`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getAllProfit = (data) => {
  return http.post(
    `analytics/profit-stats`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getDailyProfitAllProviders = (data) => {
  return http.post(
    `analytics/daily-profits`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};

const getTotalPurchase = (data) => {
  return http.post(
    `user/total-purchase`,
    data,

    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};
const getliveData = () => {
  return http.get(`analytics/user-stats`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getUsersByRegion = ({ startDate, endDate }) => {
  return http.get(`analytics/user-region?startDate=${startDate}&endDate=${endDate}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const getNewUsersPerMonth = ({ startDate, endDate }) => {
  return http.get(
    `analytics/graph/monthyly-new-users?startDate=${startDate}-01&endDate=${endDate}-01`,
    {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
    }
  );
};



const getFailedSignUpByRegion = ({ startDate, endDate }) => {
  return http.get(`analytics/black-listed-signups?startDate=${startDate}&endDate=${endDate}`, {
    headers: {
      "Content-type": "application/json",
      "x-auth-token": `${storage.get("xAuthToken")}`,
    },
  });
};

const DashboardServices = {
  getNewUser,
  getActiveUser,
  getActiveUsers,
  getTopGames,
  getBets,
  getCashout,
  getProfit,
  getAllProfit,
  getTotalPurchase,
  getliveData,
  getDailyProfitAllProviders,
  getNewUsersPerMonth,
  getUsersByRegion,
  getFailedSignUpByRegion,
};

export default DashboardServices;
