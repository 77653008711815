import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SingleUserService from "./../api/singleUser";
import PartnerServices from "store/api/partnersServices";
import SocketService from "services/socket.service";
import Toast from "components/Toastify/Toastify";
import storage from "services/storage";

const DateConverter = (d) => {
  const date = new Date(d).toLocaleDateString();
  return date;
};

const initialState = {
  data: {},
  notes: [],
  referralUsers: [],
  topGames: [],
  timePlayed: 0,
  totalPurchases: 0,
  totalCashout: 0,
  winLoss: {
    win: 0,
    total: 0,
    loss: 0,
  },
  profit: {
    options: [],
    data: [],
  },
  refferalLinks: [],
  unverifiedUsers: {
    totalPages: 1,
    users: [],
    totalCount: 0
  },
  status: "idle",
  notesStatus: "idle",
  reffralStatus: "idle",
  purchasesStatus: "idle",
  cashoutStatus: "idle",
  gamesStatus: "idle",
  timePlayedStatus: "idle",
  winLossRatioStatus: "idle",
  profitStatus: "idle",
  referralLinksStatus: "idle",
  unverifiedUsersStatus: "idle",
};

export const retriveSinglePartner = createAsyncThunk(
  "partner/retrieve",
  async ({ id, history }) => {
    const res = await PartnerServices.getSinglePartner({ id });

    res.data.myStatus = "a";
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      res.data.myStatus = "error";
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const enablePartner = createAsyncThunk(
  "partner/enable/disable",
  async ({ data, history }) => {
    const res = await PartnerServices.enable(data);
    if (res.data.success === true) {
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return data.status;
  }
);

export const flagPartner = createAsyncThunk("partner/flag", async ({ id, data, history }) => {
  const res = await PartnerServices.flag(id, data);
  if (res.data.success === true) {
    Toast.success(
      data.isFlag === true ? "Partner Flagged Successfully" : "Partner Un-Flagged Successfully",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return data.isFlag;
});

export const cashoutAllow = createAsyncThunk("partner/cashoutAllow", async ({ id, data, history }) => {
  const res = await PartnerServices.cashoutAllow(id, data);
  if (res.data.success === true) {
    Toast.success(
      data.payoutsAllowed === true ? "Cashout is enabled Successfully" : "Cashout is disabled Successfully",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return data.payoutsAllowed;
});

export const editPartner = createAsyncThunk("partner/edit", async ({ id, data, history }) => {
  const res = await PartnerServices.update(id, data);
  if (res.data.success === true) {
    Toast.success("Partner updated successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const payoutPartner = createAsyncThunk("partner/payout", async ({ id, data, history }) => {
  const res = await PartnerServices.payout(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return data;
});

export const payoutParentPartner = createAsyncThunk("partner/parentPayout", async ({ id, data, history }) => {
  const res = await PartnerServices.parentpayout(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return data;
});
export const affiliateParentPartner = createAsyncThunk("partner/affiliateParent", async ({ id, data, history }) => {
  const res = await PartnerServices.affiliateParent(id, data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const retrivePartnerNotes = createAsyncThunk("partner/notesretrieve", async ({ id }) => {
  const res = await PartnerServices.getNotes(id);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data.Data;
});
export const addPartnerNotes = createAsyncThunk("partner/AddNotes", async ({ data, history }) => {
  const res = await PartnerServices.addNotes(data);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data.Data;
});

export const deletePartnerNotes = createAsyncThunk("partner/delNote", async ({ data, history }) => {
  let userId = data.userId;
  let noteId = data.noteId;
  const res = await PartnerServices.deleteNotes(userId, noteId);
  if (res.data.success === true) {
    Toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return noteId;
});

export const retriveRefferalUsers = createAsyncThunk(
  "partner/getRefferal",
  async ({ id, history }) => {
    const res = await PartnerServices.getRefferal({ id });
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data.data;
  }
);

export const retrivePurchaseUsers = createAsyncThunk(
  "partner/purchaseUsers",
  async ({ id, history }) => {
    const res = await PartnerServices.userPurchased(id);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      //   Toast.error(res.data.message, {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "dark",
      //   });
    }
    return res.data;
  }
);

export const retriveCashoutUsers = createAsyncThunk(
  "partner/CashoutUsers",
  async ({ id, history }) => {
    const res = await PartnerServices.userCashout(id);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      //   Toast.error(res.data.message, {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "dark",
      //   });
    }
    return res.data;
  }
);

export const refferalTopGames = createAsyncThunk(
  "partner/topGames",
  async ({ id, dataGame, history }) => {
    const res = await PartnerServices.topGames(id, dataGame);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const refferalTimePlayed = createAsyncThunk(
  "partner/timePlayed",
  async ({ id, dataTime, history }) => {
    const res = await PartnerServices.timePlayed(id, dataTime);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const refferalWinLoss = createAsyncThunk(
  "refferal/winLoss",
  async ({ id, dataTime, history }) => {
    const res = await PartnerServices.winLossRatio(id, dataTime);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const refferalProfit = createAsyncThunk("refferal/profit", async ({ id, data, history }) => {
  const res = await PartnerServices.getProfit(id, data);
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});

export const getRefferalLinks = createAsyncThunk(
  "refferal/links",
  async ({ id, data, history }) => {
    const res = await PartnerServices.getRefferalLinks(id);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const updateRefferalLink = createAsyncThunk(
  "refferal/links/update",
  async ({ id, data, history }) => {
    const res = await PartnerServices.updateRefferalLink(id, data);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else{
      Toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return id;
  }
);

export const getUnverifiedUsers = createAsyncThunk(
  "getUnverifiedUsers",
  async ({ id, data, history }) => {
    const res = await PartnerServices.unverifiedUsers(id, data);
    if (
      (res.data.success == false &&
        res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
      res.data.message === "Sorry! Services not available in your country." ||
      res.data.message === "Sorry! Services not available in your region." ||
      res.data.message === "You cannot use LionsDen with a VPN or Proxy."
    ) {
      storage.remove("xAuthToken");
      storage.remove("user");
      SocketService.disconnect();
      history.push("/authentication/sign-in");
      Toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    return res.data;
  }
);

export const makeParent = createAsyncThunk("partner/makeParent", async ({ id, history }) => {
  const res = await PartnerServices.makeParent(id);
  if (res.data.success === true) {
    Toast.success(
      res.data.message,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  }else{
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  if (
    (res.data.success == false &&
      res.data.message === "Your Session has been expired, Kindly sign in to continue.") ||
    res.data.message === "Sorry! Services not available in your country." ||
    res.data.message === "Sorry! Services not available in your region." ||
    res.data.message === "You cannot use LionsDen with a VPN or Proxy."
  ) {
    storage.remove("xAuthToken");
    storage.remove("user");
    SocketService.disconnect();
    history.push("/authentication/sign-in");
    Toast.error(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  return res.data;
});



const singlePartnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    idleStatePartner: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retriveSinglePartner.pending, (state, action) => {
      state.status = "pending";
    }),
      builder.addCase(retriveSinglePartner.fulfilled, (state, action) => {
        state.data = action.payload?.user;
        state.status = action.payload.myStatus === "error" ? "rejected" : "fullfilled";
      }),
      builder.addCase(retriveSinglePartner.rejected, (state, action) => {
        state.status = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(enablePartner.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(enablePartner.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.data.status = action.payload;
        // state.data.user.status
      }),
      builder.addCase(enablePartner.rejected, (state, action) => {
        state.status = "rejected";
      }),
      builder.addCase(flagPartner.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(flagPartner.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.data.isFlag = action.payload;
        // state.data.user.status
      }),
      builder.addCase(flagPartner.rejected, (state, action) => {
        state.status = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(editPartner.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(editPartner.fulfilled, (state, action) => {
        state.data = action.payload?.user;
        state.status = "fullfilled";
      }),
      builder.addCase(editPartner.rejected, (state, action) => {
        state.status = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(payoutPartner.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(payoutPartner.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.data.payoutPercentage = action.payload.payoutPercentage;
        // state.data.user.status
      }),
      builder.addCase(payoutPartner.rejected, (state, action) => {
        state.status = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(payoutParentPartner.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(payoutParentPartner.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.data.parentPercentage = action.payload.parentPercentage;
        // state.data.user.status
      }),
      builder.addCase(payoutParentPartner.rejected, (state, action) => {
        state.status = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(affiliateParentPartner.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(affiliateParentPartner.fulfilled, (state, action) => {
        
        state.data = action.payload?.user;
        state.status = "fulfilled";
        // state.data.user.status
      }),
      builder.addCase(affiliateParentPartner.rejected, (state, action) => {
        state.status = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(retrivePartnerNotes.pending, (state, action) => {
        state.notesStatus = "pending";
      }),
      builder.addCase(retrivePartnerNotes.fulfilled, (state, action) => {
        state.notesStatus = "fulfilled";
        state.notes = action.payload;
        // state.data.user.status
      }),
      builder.addCase(retrivePartnerNotes.rejected, (state, action) => {
        state.notesStatus = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(addPartnerNotes.pending, (state, action) => {
        state.notesStatus = "pending";
      }),
      builder.addCase(addPartnerNotes.fulfilled, (state, action) => {
        state.notesStatus = "fulfilled";
        state.notes.push(action.payload);
        // state.data.user.status
      }),
      builder.addCase(addPartnerNotes.rejected, (state, action) => {
        state.notesStatus = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(deletePartnerNotes.pending, (state, action) => {
        state.notesStatus = "pending";
      }),
      builder.addCase(deletePartnerNotes.fulfilled, (state, action) => {
        const index = state.notes.findIndex((item) => item._id === action.payload);
        state.notes.splice(index, 1);
        state.notesStatus = "fulfilled";
      }),
      builder.addCase(deletePartnerNotes.rejected, (state, action) => {
        state.notesStatus = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(retriveRefferalUsers.pending, (state, action) => {
        state.reffralStatus = "pending";
      }),
      builder.addCase(retriveRefferalUsers.fulfilled, (state, action) => {
        state.referralUsers = action.payload;
        state.reffralStatus = "fulfilled";
      }),
      builder.addCase(retriveRefferalUsers.rejected, (state, action) => {
        state.reffralStatus = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(retrivePurchaseUsers.pending, (state, action) => {
        state.purchasesStatus = "pending";
      }),
      builder.addCase(retrivePurchaseUsers.fulfilled, (state, action) => {
        const data = action.payload?.data || [];
        if (data?.length === 0) {
          state.totalPurchases = 0;
        } else {
          state.totalPurchases = action.payload.data;
        }
        state.purchasesStatus = "fulfilled";
      }),
      builder.addCase(retrivePurchaseUsers.rejected, (state, action) => {
        state.purchasesStatus = "rejected";
      }),
      builder.addCase(retriveCashoutUsers.pending, (state, action) => {
        state.cashoutStatus = "pending";
      }),
      builder.addCase(retriveCashoutUsers.fulfilled, (state, action) => {
        const data = action.payload?.data || [];
        if (data?.length === 0) {
          state.totalCashout = 0;
        } else {
          state.totalCashout = action.payload?.data?.referralUserTotalCashedOut;
        }
        state.cashoutStatus = "fulfilled";
      }),
      builder.addCase(retriveCashoutUsers.rejected, (state, action) => {
        state.cashoutStatus = "rejected";
      }),
      builder.addCase(refferalTopGames.pending, (state, action) => {
        state.gamesStatus = "pending";
      }),
      builder.addCase(refferalTopGames.fulfilled, (state, action) => {
        state.topGames = action.payload?.data;
        state.gamesStatus = "fulfilled";
      }),
      builder.addCase(refferalTopGames.rejected, (state, action) => {
        state.gamesStatus = "rejected";
      }),
      builder.addCase(refferalTimePlayed.pending, (state, action) => {
        state.timePlayedStatus = "pending";
      }),
      builder.addCase(refferalTimePlayed.fulfilled, (state, action) => {
        state.timePlayed = action.payload?.data;
        state.timePlayedStatus = "fulfilled";
      }),
      builder.addCase(refferalTimePlayed.rejected, (state, action) => {
        state.timePlayedStatus = "rejected";
      }),
      builder.addCase(refferalWinLoss.pending, (state, action) => {
        state.winLossRatioStatus = "pending";
      }),
      builder.addCase(refferalWinLoss.fulfilled, (state, action) => {
        state.winLoss =
          action.payload.success === true
            ? action?.payload?.data
            : {
                win: 0,
                total: 0,
                loss: 0,
              };
        state.winLossRatioStatus = "fulfilled";
      }),
      builder.addCase(refferalWinLoss.rejected, (state, action) => {
        state.winLossRatioStatus = "rejected";
      }),
      builder.addCase(refferalProfit.pending, (state, action) => {
        state.profitStatus = "pending";
      }),
      builder.addCase(refferalProfit.fulfilled, (state, action) => {
        const temp = action.payload.data || [];
        temp?.sort((a, b) => new Date(a.date) - new Date(b.date));
        const countArray = temp?.map((item) => item?.allReferralProfit?.toFixed(2));
        const dateArray = temp?.map((item) => DateConverter(item?.date));
        state.profit.options = countArray;
        state.profit.data = dateArray;
        state.profitStatus = "fullfilled";
      }),
      builder.addCase(refferalProfit.rejected, (state, action) => {
        state.profitStatus = "rejected";
      }),
      builder.addCase(getRefferalLinks.pending, (state, action) => {
        state.referralLinksStatus = "pending";
      }),
      builder.addCase(getRefferalLinks.fulfilled, (state, action) => {
        state.refferalLinks = action.payload?.data || [];
        state.referralLinksStatus = "fullfilled";
      }),
      builder.addCase(getRefferalLinks.rejected, (state, action) => {
        state.referralLinksStatus = "rejected";
      }),
      builder.addCase(updateRefferalLink.pending, (state, action) => {
        state.referralLinksStatus = "pending";
      }),
      builder.addCase(updateRefferalLink.fulfilled, (state, action) => {
        const index = state.refferalLinks.findIndex((item) => item._id === action.payload);
        state.refferalLinks.splice(index, 1);
        state.referralLinksStatus = "fullfilled";
      }),
      builder.addCase(updateRefferalLink.rejected, (state, action) => {
        state.referralLinksStatus = "rejected";
      }),
      builder.addCase(cashoutAllow.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(cashoutAllow.fulfilled, (state, action) => {
        state.data.payoutsAllowed = action.payload;
        state.status = "fulfilled";
        // state.data.user.status
      }),
      builder.addCase(cashoutAllow.rejected, (state, action) => {
        state.status = "rejected";
        Toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }),
      builder.addCase(getUnverifiedUsers.pending, (state, action) => {
        state.unverifiedUsersStatus = "pending";
      }),
      builder.addCase(getUnverifiedUsers.fulfilled, (state, action) => {
        state.unverifiedUsers.users = action.payload?.data || [];
        state.unverifiedUsers.totalCount = action.payload?.totalCount || 0;
        state.unverifiedUsers.totalPages = action.payload?.totalPages || 1;
        state.unverifiedUsersStatus = "fullfilled";
      }),
      builder.addCase(getUnverifiedUsers.rejected, (state, action) => {
        state.unverifiedUsersStatus = "rejected";
      }),
      builder.addCase(makeParent.pending, (state, action) => {
        state.status = "pending";
      }),
      builder.addCase(makeParent.fulfilled, (state, action) => {
        if(action.payload.success === true){
          state.data = action.payload?.partner;
        }
        state.status = "fulfilled";
        // state.data.user.status
      }),
      builder.addCase(makeParent.rejected, (state, action) => {
        state.status = "rejected";
      })
  },
});

export const { idleStatePartner } = singlePartnerSlice.actions;
export default singlePartnerSlice.reducer;
