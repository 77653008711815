import { useState, useEffect, Suspense } from "react";
import './global.css'
// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import VuiBox from "components/VuiBox";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";

import createCache from "@emotion/cache";

import routes from "routes";
import routesSupport from "routesSupport";
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { Provider, useDispatch } from "react-redux";
import store from "store/Store";
import { getUsersList } from "store/api/allUserList";
import SocketConnection from "./components/SocketConnection";
import { CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    // <div>.</div>
    <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </VuiBox>
  );
};

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const role = localStorage.getItem("role");
  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     // stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  // useEffect(() => {
  //   document.body.setAttribute("dir", direction);
  // }, [direction]);

  //================> calling the users list for the users list Component <==============
  // useEffect(() => {
  //   getUsersList()
  // }, []);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // if (route.collapse) {
      //   return getRoutes(route.collapse);
      // }
      
      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.id} />;
      }

      return null;
    });

  const configsButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="info"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </VuiBox>
  );

  return (
    <>
      <Provider store={store}>
        <SocketConnection />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand=""
                brandName="LionsDen"
              routes={role=== 'ADMIN' ? routes : routesSupport}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator /> */}
              {/* {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Suspense fallback={<Loading />}>
            <Switch>
              {getRoutes(role === 'ADMIN' ? routes : routesSupport )}
              <Redirect from="*" to={role === 'ADMIN' ? "/dashboard" : "/users"} />
            </Switch>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </>
  );
}
